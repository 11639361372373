import { Pipe, PipeTransform } from '@angular/core';
import { humanizeFileSize } from '@shared/classes';
@Pipe({
  name: 'humanizeFileSize',
})
export class HumanizeFileSizePipe implements PipeTransform {
  transform(bytes: number): string {
    return humanizeFileSize(bytes);
  }
}
