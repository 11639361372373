import { Component, OnInit } from '@angular/core';
import { BaseFilter } from '@shared/classes';

@Component({
  selector: 'app-boolean-filter',
  templateUrl: './boolean-filter.component.html',
  styleUrls: ['./boolean-filter.component.scss'],
})
export class BooleanFilterComponent extends BaseFilter implements OnInit {
  buttonFilter = null;
  buttonModeOptions = [
    { label: 'False', value: false, icon: 'pi pi-times', tooltip: 'Value is False' },
    { label: 'None', value: null, icon: 'pi pi-circle', tooltip: 'No Filter' },
    { label: 'True', value: true, icon: 'pi pi-check', tooltip: 'Value is True' },
  ];
  constructor() {
    super();
  }

  ngOnInit(): void {
    this.initInput();
  }

  initInput() {}
}
