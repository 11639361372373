<!-- <app-date-view [data]="date"></app-date-view> -->
@if (badgeView) {
  <app-badge-item
    [fullWidth]="true"
    [badgeType]="badgeType"
    [text]="badgeText"
    [displayText]="false"
    [status]="showConditionalContent ? 'red' : 'green'"
    style="width: 100%"
    [styleClass]="'md:flex-row flex-column'"
  >
    <ng-content select="[badge-text]"></ng-content>
    <ng-container *ngTemplateOutlet="timer"></ng-container>
  </app-badge-item>
} @else {
  @if (date) {
    <div class="flex gap-2 w-fit justify-content-center align-items-center white-space-nowrap text-900">
      @if (showTimerStatic && showTimer) {
        <div class="timer mt-0">
          <p id="count-down">
            @if (days > 0) {
              <span>
                <span class="timer-num">{{ days.toLocaleString('en') }}</span>
                <span class="padding-l">:</span>
                @if (showTimeText) {
                  <span class="timer-cal">{{ 'Days' }}</span>
                }
              </span>
            }
            @if (hours > 0) {
              <span>
                <span class="timer-num">{{ hours.toLocaleString('en') }}</span>
                <span class="padding-l">:</span>
                @if (showTimeText) {
                  <span class="timer-cal">{{ 'Hrs' }}</span>
                }
              </span>
            }
            @if (minutes >= 0) {
              <span>
                <span class="timer-num">{{ minutes.toLocaleString('en') }}</span>
                <span class="padding-l">:</span>
                @if (showTimeText) {
                  <span class="timer-cal">{{ 'Min' }}</span>
                }
              </span>
            }
            @if (seconds >= 0) {
              <span>
                <span class="timer-num">{{ seconds.toLocaleString('en') }}</span>
                @if (showTimeText) {
                  <span class="timer-cal">{{ 'Sec' }}</span>
                }
              </span>
            }
          </p>
        </div>
      }
      @if (showConditionalContent) {
        <ng-content select="[conditional-content]"></ng-content>
      }
    </div>
  }
}
<ng-template #timer>
  @if (date) {
    <div class="flex gap-2 w-fit justify-content-center align-items-center white-space-nowrap text-900">
      @if (showTimerStatic && showTimer) {
        <div class="timer mt-0">
          <p id="count-down">
            @if (days > 0) {
              <span>
                <span class="timer-num">{{ days.toLocaleString('en') }}</span>
                <span class="padding-l">:</span>
                @if (showTimeText) {
                  <span class="timer-cal">{{ 'Days' }}</span>
                }
              </span>
            }
            @if (hours > 0) {
              <span>
                <span class="timer-num">{{ hours.toLocaleString('en') }}</span>
                <span class="padding-l">:</span>
                @if (showTimeText) {
                  <span class="timer-cal">{{ 'Hrs' }}</span>
                }
              </span>
            }
            @if (minutes >= 0) {
              <span>
                <span class="timer-num">{{ minutes.toLocaleString('en') }}</span>
                <span class="padding-l">:</span>
                @if (showTimeText) {
                  <span class="timer-cal">{{ 'Min' }}</span>
                }
              </span>
            }
            @if (seconds >= 0) {
              <span>
                <span class="timer-num">{{ seconds.toLocaleString('en') }}</span>
                @if (showTimeText) {
                  <span class="timer-cal">{{ 'Sec' }}</span>
                }
              </span>
            }
          </p>
        </div>
      }
      @if (showConditionalContent) {
        <ng-content select="[conditional-content]"></ng-content>
      }
    </div>
  }
</ng-template>
