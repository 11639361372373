import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DynamicComponentBase } from '@shared/classes/view/DynamicComponentBase';
import { ConfigService } from 'app/app.config.service';

@Component({
  selector: 'app-monaco-editor-input',
  templateUrl: './monaco-editor-input.component.html',
  styleUrls: ['./monaco-editor-input.component.scss'],
  providers: [{ provide: DynamicComponentBase, useExisting: MonacoEditorInputComponent }],
})
export class MonacoEditorInputComponent extends DynamicComponentBase implements OnInit {
  @Output() onFocus: EventEmitter<any> = new EventEmitter();
  @Input() height: any;
  editorOptions = { theme: 'vs-dark', language: 'html' };
  @Input() set editorLanguage(language: string) {
    this.editorOptions = { theme: this.currentTheme, language: language };
  }
  get code() {
    return this._code;
  }
  set code(val) {
    this._code = val;
    this.onChange(val);
  }
  _code: string = '';
  editor;
  isLoadingEditor = true;
  currentTheme: 'vs-dark' | 'vs' = 'vs-dark';
  reloadEditor = false;
  constructor(public configService: ConfigService) {
    super();
    const newTheme1: 'vs-dark' | 'vs' = this.configService?.config?.dark ? 'vs-dark' : 'vs';
    if (this.currentTheme != newTheme1) {
      this.currentTheme = newTheme1;
      this.editorOptions = { theme: newTheme1, language: this.editorOptions?.language };
    }
    this.subs.sink = this.configService.configUpdate$.subscribe((appConfig) => {
      const newTheme: 'vs-dark' | 'vs' = appConfig.dark ? 'vs-dark' : 'vs';
      if (this.currentTheme != newTheme) {
        this.currentTheme = newTheme;
        this.editorOptions = { theme: newTheme, language: this.editorOptions?.language };
        this.reloadEditor = true;
        setTimeout(() => {
          this.reloadEditor = false;
        }, 200);
      }
    });
  }

  ngOnInit(): void {}
  setInputOptions() {}
  onFocusChange(event) {
    this.onFocus.emit(event);
  }

  onInit(editor) {
    this.code = this.fControl.value;
    this.isLoadingEditor = false;
    // this.editor = editor;
    // editor.getModules()onDidChangeModelContent(x => {
    //   this.fControl.patchValue(this.code);
    // });
    // return;
  }
  onChange(value) {
    this.fControl.patchValue(value);
  }
}
