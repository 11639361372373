import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-skeleton-loader-placeholder',
  templateUrl: './skeleton-loader-placeholder.component.html',
  styleUrls: ['./skeleton-loader-placeholder.component.scss'],
})
export class SkeletonLoaderPlaceholderComponent implements OnInit {
  public _isLoading: boolean;
  get isLoading() {
    return this._isLoading;
  }
  @Input() set isLoading(isLoading: boolean) {
    this._isLoading = isLoading;
    this.onSetIsLoading();
  }
  onSetIsLoading() {}
  @Input() skeletonType: 'button' | 'circle' = 'button';
  @Input() staticWidth = '85px';
  constructor() {}

  ngOnInit(): void {}
}
