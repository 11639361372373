import { HttpEventType, HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  BaseViewItem,
  DocumentVersionsDto,
  FileViewerItem,
  FileViewerType,
  getFileViewerType,
  IAction,
  MessageKeys,
} from '@shared/classes';
import { AppDialogService, ToastService } from '@shared/services';
import { FilesDataService } from 'app/modules/file-manager-module/services/files-data.service';
import { VividIconsCatalog } from 'assets/icons/vivid/icons/vivid/catalog';
import {
  FileViewerPopupComponent,
  FileViewerPopupOptions,
} from '../file-viewer/file-viewer-popup/file-viewer-popup.component';

@Component({
  selector: 'app-attachment-item-view',
  templateUrl: './attachment-item-view.component.html',
  styleUrls: ['./attachment-item-view.component.scss'],
  host: {
    '[style.min-width]': '0',
  },
})
export class AttachmentItemViewComponent extends BaseViewItem<DocumentVersionsDto> implements OnInit {
  @Input() extraText: string;
  @Input() progressBg: string;
  private _documentName: string;
  get documentName() {
    return this._documentName;
  }
  @Input() set documentName(documentName: string) {
    this._documentName = documentName;
    this.documentExtension = this.getDocumentExtension();
  }
  @Input() progress: number = 0;
  @Input() inProgress: boolean = false;
  @Input() cancelRequestFunction: Function = this.cancelDownload.bind(this);
  @Input() useCodeDownload: boolean = true;
  @Input() showDeleteButton: boolean = true;
  @Input() externalCode: String | string;
  @Output() onCancelRequest: EventEmitter<any> = new EventEmitter();
  @Output() onDeleteItem: EventEmitter<any> = new EventEmitter();
  @Input() preivewMode: 'inline' | 'popup' = 'popup';
  documentExtension: string = 'blank';
  hasPreviewer: boolean = false;
  fileViewerType: FileViewerType = FileViewerType.OTHER;
  fileViewerObject: FileViewerItem;
  private _lastDownloadedFile: Blob;
  get lastDownloadedFile() {
    return this._lastDownloadedFile;
  }
  set lastDownloadedFile(file) {
    this._lastDownloadedFile = file;
    this.fileViewerObject = { fileName: this.documentName, file: file };
  }
  downloadAttachmentAction: IAction = {
    id: 1,
    icon: 'pi pi-cloud-download',
    iconPos: 'left',
    color: 'primary',
    command: this.downloadAttachment.bind(this, this.documentUrl),
    label: 'Download',
    buttonStyle: 'outlined',
  };
  deleteAttachmentAction: IAction = {
    id: 2,
    icon: 'pi pi-trash',
    iconPos: 'left',
    color: 'danger',
    command: this.deleteAttachment.bind(this),
    label: 'Delete',
    buttonStyle: 'outlined',
    passEvent: true,
  };
  cancelRequestAction: IAction = {
    id: 3,
    icon: 'pi pi-times',
    iconPos: 'left',
    color: 'warning',
    command: this.cancelRequestFunction.bind(this),
    buttonStyle: 'outlined',
    label: 'Stop',
  };
  constructor(
    private requestService: FilesDataService,
    private appDialogService: AppDialogService,
    private toast: ToastService
  ) {
    super();
  }

  ngOnInit() {}
  cancelDownload() {
    this.subs.unsubscribe();
    this.inProgress = false;
    this.progress = 0;
    this.onCancelRequest.emit('cancel_clicked');
  }
  isLoading: boolean = false;
  onSetData(): void {
    this.documentName = this.data?.versionName || 'file';
    this.fileViewerType = getFileViewerType(this.documentName);
    this.hasPreviewer = this.fileViewerType != FileViewerType.OTHER;

    if ((!this.data?.fileId || !this.data?.versionName) && this.data?.document) {
      this.isLoading = true;
      this.requestService.getListVersionsByDocumentCodes([this.data?.document]).subscribe({
        next: (res) => {
          this.data = res?.[0];
          this.isLoading = false;
        },
        error: (err) => {
          this.isLoading = false;
        },
      });
    } else {
      // this will get toggled at least once no matter what (because we are resetting the data in the if statement)
      if (this.preivewMode == 'inline' && !this.lastDownloadedFile && !this.inProgress) {
        if (this.hasPreviewer) {
          this.downloadAttachment(this.documentUrl, false);
        } else {
          this.fileViewerObject = { file: null, fileName: this.documentName };
        }
      }
    }
  }
  get documentUrl() {
    return this.useCodeDownload && this.externalCode
      ? `code/${this?.externalCode}/${this?.data?.fileId}`
      : this?.data?.fileId;
  }
  deleteAttachment(event) {
    this.appDialogService.confirmPopup(
      {
        accept: () => {
          this.requestService.moveToTrash([this.data.document]).subscribe((res) => {
            this.data = null;
            this.onDeleteItem.emit(true);
          });
        },
      },
      event?.target,
      {
        message: `Delete attachment?`,
        header: 'Delete',
        icon: 'pi pi-exclamation-triangle',
      }
    );
  }
  openPreviewDialog() {
    this.appDialogService.showDialog<FileViewerPopupOptions>(
      FileViewerPopupComponent,
      'Attachment Preview',
      (data) => {
        // this.cancelDownload();
      },
      {
        data: {
          documentUrl: this.documentUrl,
          documentName: this.documentName,
          documentExtension: this.documentExtension,
          progressBg: this.progressBg,
          lastDownloadedFile: this.lastDownloadedFile,
          showDeleteButton: this.showDeleteButton,
          documentData: this.data,
        },
      }
    );
  }
  downloadAttachment(id: string, saveFile: boolean = true) {
    if (this.lastDownloadedFile && saveFile) {
      this.saveFileToDisk(this.lastDownloadedFile, this.documentName);
    } else {
      this.inProgress = true;
      this.progress = 0;
      this.subs.sink = this.requestService.downloadFileWithProgress(id).subscribe({
        next: (event) => {
          if (event.type === HttpEventType.DownloadProgress) {
            this.inProgress = true;

            this.progress = (event.loaded / event.total) * 100;
          }
          if (event.type === HttpEventType.Response) {
            this.inProgress = false;
            this.progress = 0;

            const response = event;
            const blobObj = new Blob([response.body], { type: response.body.type });
            this.documentName = this.parseContentDispositionHeader(response.headers);
            this.lastDownloadedFile = blobObj;
            if (saveFile) {
              this.saveFileToDisk(blobObj, this.documentName);
            }
          }
        },
        error: (err) => {
          this.inProgress = false;
          this.progress = 0;
          this.toast.error(MessageKeys.errorHappened, MessageKeys.tryAgainLater);
        },
      });
    }
  }
  getDocumentExtension() {
    if (!this.documentName) return 'blank';
    const a = this.documentName?.split('.');
    const ret = a.length > 1 ? a[a.length - 1] : 'blank';
    return VividIconsCatalog.includes(ret) ? ret : 'blank';
  }
  saveFileToDisk(blob: Blob, fileName: string) {
    const data = URL.createObjectURL(blob);
    let link = document.createElement('a');
    link.href = data;
    link.download = fileName;
    // this is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
    // this.progress = 100;
    setTimeout(function () {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
      link.remove();
    }, 100);
  }
  parseContentDispositionHeader(headers: HttpHeaders) {
    let ret = 'file';
    const contentDisposition = headers.get('content-disposition');

    if (contentDisposition != null) {
      const fileName = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].replace(/["]+/g, '').trim();
      ret = fileName;
    } else {
      ret = this.documentName || 'file';
    }
    return ret;
  }
  onDestroy(): void {
    this.lastDownloadedFile = null;
  }
}
