import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BaseFileViewerItem } from '@shared/classes';

@Component({
  selector: 'app-text-viewer',
  templateUrl: './text-viewer.component.html',
  styleUrl: './text-viewer.component.scss'
})
export class TextViewerComponent extends BaseFileViewerItem implements OnInit {
  fileContent;
  tempFormControl:FormControl = new FormControl(null);
  editorLanguage = 'text/plain';
  constructor() {
    super();
  }
  ngOnInit(): void {}
  onSetData(): void {
    if(this.data){
      this.loadBlobContent();
      this.editorLanguage = this.data.file.type || 'text/plain';
    }else{
      this.tempFormControl.patchValue(null);
    }
  }
  loadBlobContent() {
    const reader = new FileReader();
    
    // Once the file is loaded, the result will be the content of the blob
    reader.onload = () => {
      this.fileContent = reader.result;  // Store the file content here
      this.tempFormControl.patchValue(this.fileContent);
    };

    // Read the blob as text
    reader.readAsText(this.data.file);
  }
}