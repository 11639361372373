<div class="mb-4">
  <!-- <app-comments-form (formSubmit)="onSubmit($event)"
[autoFocus]="autoFocus"></app-comments-form> -->
  <!-- <app-image-input></app-image-input> -->
  <app-attachment-input
    [control]="attachmentControl"
    [viewMode]="'edit'"
    [dragAndDropMode]="true"
    [bucketId]="'code/' + this.itemCode"
    [externalCode]="this.itemCode"
    [useGlobalPasteEventListener]="true"
    [staticUploadWidth]="true"
  ></app-attachment-input>
</div>

@if (loading) {
  <div class="flex justify-content-center align-items-center w-full">
    <!-- <p-progressSpinner></p-progressSpinner> -->
    <ul class="m-0 p-0 list-none w-full">
      <li class="mb-3">
        <div class="flex w-full">
          <p-skeleton shape="circle" size="2rem" styleClass="mr-3"></p-skeleton>
          <div style="flex: 1">
            <p-skeleton width="100%" height="7rem"></p-skeleton>
          </div>
        </div>
      </li>
    </ul>
  </div>
}

<div class="flex flex-row flex-wrap gap-1 mt-3">
  @for (event of events; track event; let i = $index) {
    <app-attachment-item-view [externalCode]="itemCode" [data]="event" (onDeleteItem)="onDeleteItem($event, i)">
    </app-attachment-item-view>
  }
</div>

<!-- <p-timeline *ngIf="!loading && events?.length > 0" [value]="events" align="left">
<ng-template pTemplate="marker" let-event>
  <span [class]="'custom-marker shadow-2 ' + 'bg-'+ colorMap['TRAIL_COMMENT'] +'-500 ' " >
    <i [ngClass]="icon"></i>
  </span>
</ng-template>

<ng-template pTemplate="content" let-event>
  <div class="card p-card">

    <div class="p-card-body">
      <div class="p-card-title">

        <div class="flex mb-2">
          <app-user-list-view
          [data]="event.createdUser ? [event.createdUser] : event.creatorName ?? ['No User']"></app-user-list-view>
          <span class="ml-2 font-medium mt-1 text-base">{{event.createdUser??event.creatorName}}</span>
          <span class="ml-2 font-normal mt-1 text-base">{{event.actionTitle ?? 'added an attachment'}}</span>
          <div class="p-card-subtitle mt-1 ml-2 flex align-items-center"
            timeago
          [date]="event.creationDate | date : 'yyyy-MM-dd HH:mm:ss'"></div>
        </div>

      </div>

      <div>
        <app-attachment-item-view
          [externalCode]="itemCode"
          [data]="event"
          >
        </app-attachment-item-view>
      </div>

    </div>

  </div>
</ng-template>
</p-timeline> -->

@if (!loading && !!events && events?.length == 0) {
  <div class="flex justify-content-center align-items-center w-full">
    <span class="font-medium text-base text-center w-full"> No items found <i class="pi pi-link"></i> </span>
  </div>
}
