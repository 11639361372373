import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BaseFilter } from '@shared/classes';

@Component({
  selector: 'app-date-filter',
  templateUrl: './date-filter.component.html',
  styleUrls: ['./date-filter.component.scss'],
})
export class DateFilterComponent extends BaseFilter implements OnInit {
  dateControl: FormControl = new FormControl();
  today = new Date();
  constructor() {
    super();
    this.today.setHours(0);
    this.today.setMinutes(0);
    this.today.setSeconds(0);
    this.today.setMilliseconds(0);
  }

  ngOnInit(): void {}
}
