import { Pipe, PipeTransform } from '@angular/core';
import { humanizeCasedString } from '@shared/classes';

@Pipe({
  name: 'humanize',
})
export class HumanizePipe implements PipeTransform {
  transform(value: string) {
    return humanizeCasedString(value);
    // return humanizeString(value);
  }
}
