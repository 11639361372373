<p-columnFilter
  [field]="col.key"
  [display]="col.filter.display || 'menu'"
  [placeholder]="col.filter.placeholder || 'Search By ' + col.name"
  [matchMode]="col.filter.matchMode"
  [showMatchModes]="col.filter.showMatchModes ?? true"
  [showOperator]="col.filter.showOperator ?? true"
  [showAddButton]="false"
  [showClearButton]="false"
  [showApplyButton]="false"
>
  <ng-template pTemplate="header">
    <div class="px-3 pt-3 pb-0">
      <span class="font-bold">{{ col.filter.placeholder || 'Search By ' + col.name }}</span>
    </div>
  </ng-template>
  <ng-template pTemplate="filter" let-value let-filter="filterCallback">
    <ng-template
      appDynamicComponent
      mode="edit"
      [manualValue]="value"
      [dynamicComponentInfo]="col.filter.dynamicInput"
    ></ng-template>
    <div class="flex justify-content-between align-items-center mt-4">
      @if (col.filter.showClearButton ?? true) {
        <p-button
          label="Clear"
          styleClass="p-button-outlined"
          (onClick)="filter(undefined); col.filter.dynamicInput.options.control.patchValue(null)"
        ></p-button>
      }
      @if (col.filter.showApplyButton ?? true) {
        <p-button label="Apply" (onClick)="filter(col.filter.dynamicInput.options.control.value)"></p-button>
      }
    </div>
  </ng-template>
</p-columnFilter>
