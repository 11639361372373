import { Component } from '@angular/core';
import { RelationsService } from '@shared/services/relation-service/relations.service';
import { QuestionsDataService } from 'app/modules/questions/service/data/question-data.service';
import { BasePage, RequestHandlerOptions } from 'app/shared/classes';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-relation-item',
  templateUrl: './relation-item.component.html',
  styleUrls: ['./relation-item.component.scss'],
})
export class RelationItemPopupComponent extends BasePage {
  apiOptions: RequestHandlerOptions = {
    showLoading: true,
    showMsg: true,
  };

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private questionsDataService: QuestionsDataService,
    private relationsService: RelationsService
  ) {
    super();
  }

  onSubmit(data) {
    // data["sourceCode"] = this.config?.data?.sourceCode;
    // const body:RelationRequestDto = {
    //     sourceCode: this.config?.data?.sourceCode,

    // }
    this.relationsService
      .createRelations(
        {
          sourceCode: this.config?.data?.sourceCode,
          relations: (data?.targetCode as any[])?.map((x) => {
            return {
              ...data,
              targetCode: x?.code,
              sourceCode: this.config?.data?.sourceCode,
              sourceUUID: this.config?.data?.sourceUUID,
              targetUUID: x?.uuid,
            };
          }),
        },
        this.apiOptions
      )
      .subscribe((res) => {
        this.ref.close(res.data);
      });
  }
}
