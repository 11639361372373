<div class="w-full flex flex-row gap-2 flex-wrap align-items-center">
  @for (item of data; track item) {
    <div (click)="navigateToGlobalSearch(item)" class="cursor-pointer">
      <app-badge-item [customColor]="stringToColorFunc(item)" [customIcon]="'hidden'" [text]="item"></app-badge-item>
    </div>
  }
  @if (lastElementTemplate) {
    <ng-container *ngTemplateOutlet="lastElementTemplate"></ng-container>
  }
</div>
