import { Component, ContentChild, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { BaseViewItem } from '@shared/classes';
import { stringToColor } from '@shared/classes/helpers/string-to-color-helper';

@Component({
  selector: 'app-tag-search-view',
  templateUrl: './tag-search-view.component.html',
  styleUrl: './tag-search-view.component.scss'
})
export class TagSearchViewComponent extends BaseViewItem implements OnInit {

  @ContentChild('lastElementTemplate') lastElementTemplate: TemplateRef<any>;

  constructor(private router: Router) {
    super();
  }

  ngOnInit(): void { }
  onSetData(): void { }

  clearData() { }

  stringToColorFunc(string) {
    return stringToColor(string)
  }

  navigateToGlobalSearch(tag) {
    this.router.navigate(['/global-search'], {
      queryParams: {
        q: null,
        tags: tag
      }
    });
  }
}
