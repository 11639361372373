import { Component, Input, OnInit } from '@angular/core';
import { BaseFilter } from '@shared/classes';

@Component({
  selector: 'app-filter-switch',
  templateUrl: './filter-switch.component.html',
  styleUrls: ['./filter-switch.component.scss'],
})
export class FilterSwitchComponent extends BaseFilter implements OnInit {
  @Input() tagsControl;
  constructor() {
    super();
  }

  ngOnInit(): void { }
}
