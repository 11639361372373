import { Component, OnInit } from '@angular/core';
import { BaseFileViewerItem, FileViewerType } from '@shared/classes';

@Component({
  selector: 'app-file-viewer-switch',
  templateUrl: './file-viewer-switch.component.html',
  styleUrl: './file-viewer-switch.component.scss',
})
export class FileViewerSwitchComponent extends BaseFileViewerItem implements OnInit {
  fileViewerTypes = FileViewerType;
  constructor() {
    super();
  }
  ngOnInit(): void {}
}
