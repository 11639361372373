import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BaseFilter, IDynamicComponent } from '@shared/classes';
import { UserAndGroupSelectorComponent } from '@shared/components/selectors/user-and-group-selector/user-and-group-selector.component';
import { UserSelectorComponent } from '@shared/components/selectors/user-selector/user-selector.component';

@Component({
  selector: 'app-user-and-group-filter',
  templateUrl: './user-and-group-filter.component.html',
  styleUrls: ['./user-and-group-filter.component.scss'],
})
export class UserAndGroupFilterComponent extends BaseFilter implements OnInit {
  dropDownInput?: IDynamicComponent;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.initInput();
  }

  initInput() {
    this.dropDownInput = {
      componentType: UserAndGroupSelectorComponent,
      options: {
        label: '',
        name: '',
        control: new FormControl(null),
        inputOptions: {},
      },
    };
  }
}
