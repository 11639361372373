import { Component, OnInit } from '@angular/core';
import { BaseFilter } from '@shared/classes';

@Component({
  selector: 'app-default-filter',
  templateUrl: './default-filter.component.html',
  styleUrls: ['./default-filter.component.scss'],
})
export class DefaultFilterComponent extends BaseFilter implements OnInit {
  constructor() {
    super();
  }

  ngOnInit(): void {}
}
