import { Component, Input, OnInit } from '@angular/core';
import { IAction } from '@shared/classes';
import { LoaderService } from '@shared/services/loader.service';

@Component({
  selector: 'app-drop-down-badge',
  templateUrl: './drop-down-badge.component.html',
  styleUrls: ['./drop-down-badge.component.scss'],
})
export class DropDownBadgeComponent implements OnInit {
  @Input() dropDownActions: IAction[] = [];
  @Input() badgeStatus: string;
  constructor(public loaderService: LoaderService) {}

  ngOnInit(): void {}
}
