@if (hrefPath) {
  @if (!label?.notFound && !badgeMode) {
    <a
      [routerLink]="hrefPath"
      [style]="color ? 'color:' + color : ''"
      [class]="showTooltip ? 'line-clamp-1-ellipsis ' : ''"
    >
      <!-- <app-badge-item *ngIf="label?.recordStatus"
      class="mr-2"
      [styleClass]="'text-xs'"
      [status]="label?.recordStatus || 'cyan'"
    [text]="code"></app-badge-item> -->
      @if (loadingLabel) {
        <i class="pi pi-spin pi-spinner text-400"></i>
      }
      @if (label?.recordStatus && badgeIcons[label?.recordStatus] && showRecordStatus) {
        <i [class]="badgeIcons[label?.recordStatus] + ' text-' + badgeColors[label?.recordStatus] + '-400 '"></i>
      }
      <!-- {{label?.recordStatus ? '' : code}} {{label?.label ? ((label?.recordStatus ? ' ': ' : ') + label?.label) : ''}} -->
      <!-- <app-badge-item *ngIf="label?.notFound"
    class="mr-2 inline-block"
    [styleClass]="'text-xs'"
    [status]="'red'"
  [text]="'Not Found'"></app-badge-item> -->
      <span title="{{ showLabel && showTooltip ? label?.label : '' }}">
        {{ code }}{{ showLabel ? (label?.label ? ': ' + label?.label : '') : '' }}</span
      >
    </a>
  } @else {
    <span class="line-clamp-1-ellipsis">
      @if (badgeMode && label?.recordStatus && badgeIcons[label?.recordStatus] && showRecordStatus) {
        <app-badge-item
          class="mr-1 inline-block"
          [styleClass]="'text-xs'"
          [status]="label?.recordStatus || 'cyan'"
          [text]="code"
        ></app-badge-item>
      }
      @if (loadingLabel) {
        <i class="pi pi-spin pi-spinner text-400"></i>
      }
      @if (label?.recordStatus && badgeIcons[label?.recordStatus] && showRecordStatus && !badgeMode) {
        <i [class]="badgeIcons[label?.recordStatus] + ' text-' + badgeColors[label?.recordStatus] + '-400 '"></i>
      }
      <!-- {{label?.recordStatus ? '' : code}} {{label?.label ? ((label?.recordStatus ? ' ': ' : ') + label?.label) : ''}}</span> -->
      <!-- <app-badge-item *ngIf="label?.notFound"
  class="mr-2 inline-block"
  [styleClass]="'text-xs'"
  [status]="'red'"
[text]="'Not Found'"></app-badge-item> -->
      <span title="{{ showLabel && showTooltip ? label?.label : '' }}">
        {{ badgeMode && !loadingLabel ? '' : code
        }}{{ showLabel ? (label?.label ? (badgeMode ? '' : ': ') + label?.label : '') : '' }}</span
      ></span
    >
  }
} @else {
  <span class="line-clamp-1-ellipsis">
    @if (badgeMode && label?.recordStatus && badgeIcons[label?.recordStatus] && showRecordStatus) {
      <app-badge-item
        class="mr-1 inline-block"
        [styleClass]="'text-xs'"
        [status]="label?.recordStatus || 'cyan'"
        [text]="code"
      ></app-badge-item>
    }
    @if (loadingLabel) {
      <i class="pi pi-spin pi-spinner text-400"></i>
    }
    @if (label?.recordStatus && badgeIcons[label?.recordStatus] && showRecordStatus && !badgeMode) {
      <i [class]="badgeIcons[label?.recordStatus] + ' text-' + badgeColors[label?.recordStatus] + '-400 '"></i>
    }
    <!-- {{label?.recordStatus ? '' : code}} {{label?.label ? ((label?.recordStatus ? ' ': ' : ') + label?.label) : ''}}</span> -->
    <!-- <app-badge-item *ngIf="label?.notFound"
  class="mr-2 inline-block"
  [styleClass]="'text-xs'"
  [status]="'red'"
[text]="'Not Found'"></app-badge-item> -->
    <span title="{{ showLabel && showTooltip ? label?.label : '' }}">
      {{ badgeMode && !loadingLabel ? '' : code
      }}{{ showLabel ? (label?.label ? (badgeMode ? '' : ': ') + label?.label : '') : '' }}</span
    ></span
  >
}
