<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
  <div class="formgrid grid p-fluid m-0">
    <div class="col-12 md:col-12">
      <app-text-editor
        [height]="'100px'"
        [advanced]="true"
        [name]="'textarea'"
        label="Comment"
        [placeholder]="'Add Comment...'"
        [control]="formGroup?.controls?.comment"
        [showOnFocus]="true"
        [showLabelInFocusMode]="false"
        pAutoFocus
        [autofocus]="autoFocus"
      >
        <app-button [action]="submitButtonAction"></app-button>
      </app-text-editor>
    </div>
  </div>

  <!-- <div class="flex flex-row-reverse gap-2">
        <app-button [action]="submitButtonAction"></app-button>
    </div> -->
</form>
