import { Component, OnInit } from '@angular/core';
import { AppComponent } from 'app/app.component';

@Component({
  selector: 'app-locked-item',
  templateUrl: './locked-item.component.html',
  styleUrls: ['./locked-item.component.scss'],
})
export class LockedItemComponent implements OnInit {
  constructor(public app: AppComponent) {}

  ngOnInit(): void {}
}
