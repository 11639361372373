<div class="mb-4">
  <app-comments-form (formSubmit)="onSubmit($event)" [autoFocus]="autoFocus"></app-comments-form>
</div>

@if (loading) {
  <div class="flex justify-content-center align-items-center w-full">
    <!-- <p-progressSpinner></p-progressSpinner> -->
    <ul class="m-0 p-0 list-none w-full">
      <li class="mb-3">
        <div class="flex w-full">
          <p-skeleton shape="circle" size="2rem" styleClass="mr-3"></p-skeleton>
          <div style="flex: 1">
            <p-skeleton width="100%" height="7rem"></p-skeleton>
          </div>
        </div>
      </li>
    </ul>
  </div>
}

@if (!loading && events?.length > 0) {
  <p-timeline class="trail-timeline" [value]="events" align="left">
    <ng-template pTemplate="marker" let-event>
      <span [class]="'custom-marker shadow-2 ' + 'bg-' + colorMap['TRAIL_COMMENT'] + '-500 '">
        <i [ngClass]="icon"></i>
      </span>
    </ng-template>
    <ng-template pTemplate="content" let-event>
      <div class="card p-card">
        <div class="p-card-body">
          <div class="p-card-title">
            <div class="flex mb-2 align-items-center">
              <app-user-list-view
                [data]="event.createdUser ? [event.createdUser] : (event.creatorName ?? ['No User'])"
              ></app-user-list-view>
              <!-- <span class="ml-2 font-medium mt-1 text-base">{{ event.createdUser ?? event.creatorName }}</span> -->
              <span class="ml-2 font-normal text-base">{{ event.actionTitle ?? 'added a Comment' }}</span>
              <div
                class="text-400 text-sm ml-2 flex align-items-center"
                timeago
                [date]="event.creationDate | date: 'yyyy-MM-dd HH:mm:ss'"
              ></div>
            </div>
          </div>
          <div>
            <p [innerHTML]="event.comment"></p>
          </div>
        </div>
      </div>
    </ng-template>
  </p-timeline>
}

@if (!loading && !!events && events?.length == 0) {
  <div class="flex justify-content-center align-items-center w-full">
    <span class="font-medium text-base text-center w-full"> No items found <i class="pi pi-comments"></i> </span>
  </div>
}
