<p-columnFilter
  [field]="col.key"
  [display]="col.filter.display || 'menu'"
  [placeholder]="col.filter.placeholder || 'Search By ' + col.name"
  [matchMode]="col.filter.matchMode"
  [type]="col.filter.type"
  [showMatchModes]="col.filter.showMatchModes ?? true"
  [showOperator]="col.filter.showOperator ?? true"
  [showAddButton]="true"
  [showClearButton]="true"
  [showApplyButton]="true"
  [maxConstraints]="10"
  #dateFilter
>
  <ng-template pTemplate="header">
    <div class="px-3 pt-3 pb-0">
      <span class="font-bold">{{ col.filter.placeholder || 'Search By ' + col.name }}</span>
    </div>
  </ng-template>
  <ng-template pTemplate="filter" let-value let-filter="filterCallback">
    <!-- <app-date-input
    [label]="'Date'"
    [placeholder]="'11/22/2022 23:59'"
    [name]="'Date'"
    [showIcon]="false"
    [control]="dateControl"
    [viewMode]="'edit'"
    [showTime]="true"
    [floatLabel]="false"

    ></app-date-input> -->
    <div class="field">
      <span class="w-full">
        <p-calendar
          [showClear]="false"
          [showIcon]="false"
          [placeholder]="'11/22/2022 23:59'"
          [inputId]="'Date'"
          [dateFormat]="'yy-mm-dd'"
          [hourFormat]="'24'"
          [showTime]="true"
          [ngModel]="value"
          [defaultDate]="today"
          (ngModelChange)="filter($event)"
        ></p-calendar>
      </span>
    </div>
    <!-- <div class="flex justify-content-between align-items-center mt-4">
        <p-button *ngIf="col.filter.showClearButton ?? true" label="Clear" styleClass="p-button-outlined" (onClick)="filter(undefined);dateControl.patchValue(null);"></p-button>
        <p-button *ngIf="col.filter.showApplyButton ?? true" label="Apply" (onClick)="filter(dateControl.value)"></p-button>
    </div> -->
  </ng-template>
</p-columnFilter>
