<app-button-list
  class="align-self-center"
  [listType]="'dropdown'"
  [dropdownActionLabel]="''"
  [dropdownActionIcon]="'fas fa-layer-group'"
  [dropdownActionColor]="'secondary'"
  [dropdownActionStyle]="'rounded-text'"
  [actionList]="groupingActions"
  [commandData]="null"
  [appendTo]="null"
>
</app-button-list>
