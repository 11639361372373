import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BaseFilter, IAction } from '@shared/classes';

@Component({
  selector: 'app-table-row-group-switch',
  templateUrl: './table-row-group-switch.component.html',
  styleUrls: ['./table-row-group-switch.component.scss'],
})
export class TableRowGroupSwitchComponent extends BaseFilter implements OnInit {
  @Output() onRowGroup: EventEmitter<any> = new EventEmitter();
  groupingActions: IAction[] = [
    {
      id: 1,
      label: 'Header Row',
      command: this.selectRowGroupingMode.bind(this, 'subheader'),
      color: 'primary',
    },
    {
      id: 2,
      label: 'Expand Row',
      command: this.selectRowGroupingMode.bind(this, 'expand'),
      color: 'primary',
    },
    {
      id: 3,
      label: 'Span Row',
      command: this.selectRowGroupingMode.bind(this, 'rowspan'),
      color: 'primary',
    },
  ];
  constructor() {
    super();
  }

  ngOnInit(): void {}
  selectRowGroupingMode(mode: 'rowspan' | 'subheader' | 'expand') {
    this.onRowGroup.emit({ mode: mode, column: this.col });
  }
}
