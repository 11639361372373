import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { LocaleService } from '@core/services/locale/locale.service';
import {
  BaseTablePage,
  DataTypeEnum,
  IColumn,
  IViewMode,
  ModuleKeywords,
  OfflineConfig,
  RelationItemDto,
  TargetTypeEnum,
  getModuleKeywordByCode,
} from '@shared/classes';
import { UserSelectorComponent } from '@shared/components/selectors/user-selector/user-selector.component';
import { AppDialogService } from '@shared/services/app-dialog.service';
import { ExportDataService } from '@shared/services/export-data.service';
import { RelationsService } from '@shared/services/relation-service/relations.service';
import { reverse } from 'lodash-es';
import { Subject } from 'rxjs';
import { RelationItemPopupComponent } from '../relation-popup/relation-item.component';

@Component({
  selector: 'app-relationTab',
  templateUrl: './relationTab.component.html',
  styleUrls: ['./relationTab.component.scss'],
})
export class RelationTabComponent extends BaseTablePage<RelationItemDto> implements OnInit {
  @Input()
  typesMap: any;

  private _sourceCode: string;
  get sourceCode(): string {
    return this._sourceCode;
  }
  @Input() set sourceCode(value: string) {
    this._sourceCode = value;
    this.clearData();
    this.loadData();
  }

  @Input() sourceUUID: string;
  @Input()
  niceMode: Boolean;
  @Input()
  subject: Subject<Boolean>;
  @Input()
  refreshData: Subject<Boolean>;

  @Input()
  relationType: TargetTypeEnum;

  @Input()
  fromType: TargetTypeEnum;

  @Input() mode: IViewMode = IViewMode.edit;

  @Output() addedNew: EventEmitter<Boolean> = new EventEmitter();
  @Output() onLoadData: EventEmitter<any[]> = new EventEmitter();

  offlineConfig: OfflineConfig = {
    lazy: false,
    paginator: true,
    showActionBar: false,
  };

  originalData: any[] = null;
  niceCols: IColumn[] = [];

  newProperty;
  relationTypes: any;

  constructor(
    private requestService: RelationsService,
    private relationsService: RelationsService,
    exportService: ExportDataService,
    appDialogService: AppDialogService,
    router: Router,
    public localeService: LocaleService
  ) {
    super(
      requestService,
      exportService,
      appDialogService,
      router,
      {
        moduleKeyword: ModuleKeywords.Question,
      },
      localeService
    );

    this.newProperty = {
      breadCrumb: {
        items: [],
      },
      pageActions: [],
    };

    this.SetPageValues(this.newProperty);
  }

  onSearchQuestionClick() {
    this.appDialogService.showDialog(
      RelationItemPopupComponent,
      'Add Relation',
      (e) => {
        if (e) {
          // this.clearData();
          this.originalData = null;
          this.loadData();
          this.addedNew.next(true);
        }
      },
      {
        data: {
          typesMap: this.typesMap,
          relationType: this.fromType,
          sourceCode: this.sourceCode,
          sourceUUID: this.sourceUUID,
        },
        width: '80%',
        height: 'fit-content',
      }
    );
  }

  addNewItem() {
    this.onSearchQuestionClick();
  }

  ngOnInit(): void {
    if (this.mode == IViewMode.edit) {
      const currentModuleKeyword = getModuleKeywordByCode(this.sourceCode);
      this.tableActions = [
        {
          id: 2,
          icon: 'pi pi-trash',
          buttonStyle: 'text',
          color: 'danger',
          tooltipText: 'Delete Relation',
          tooltipPosition: 'top',
          command: this.deleteItem.bind(this),
          permission: `delete${currentModuleKeyword}`,
          displayCommand: (row: RelationItemDto) => row?.relationDirection == 'MANUAL',
        },
      ];
    } else {
      this.tableActions = [];
    }

    this.subs.sink = this.subject.subscribe((fire) => {
      if (fire) {
        this.onSearchQuestionClick();
      }
    });
    this.subs.sink = this.refreshData.subscribe((fire) => {
      if (fire) {
        // this.clearData();
        this.originalData = null;
        this.loadData();
      }
    });
    // this.relationsService.getRelationTypes().subscribe(res => {

    // })
    this.relationsService
      .searchRelationTypes(
        { all: true },
        {},
        { showLoading: true, showMsg: false, extras: { cacheKey: 'allRelationTypes' } }
      )
      .subscribe((res) => {
        this.relationTypes = res;
        this.setCols();
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes?.relationType?.firstChange && changes?.relationType) {
      this.onChangeRelationType();
    }
  }
  //@TODO: find out why is this thing needing a request on it's own and not just using the call from the parent component relations.component.ts
  loadData() {
    if (!this.originalData) {
      this.isLoading = true;
      this.subs.sink = this.relationsService.getBySourceCode(this.sourceCode).subscribe({
        next: (res: any) => {
          this.tableData = reverse(res.data);
          this.originalData = reverse(res.data);

          if (!!this.relationType) {
            this.tableData = reverse(res.data.filter((el) => el.targetType == this.relationType));
          }
          this.onLoadData.emit(this.originalData);
          this.isLoading = false;
        },
        error: (error) => {
          this.isLoading = false;
        },
        complete: () => {},
      });
    } else {
      this.tableData = this.originalData;

      if (!!this.relationType) {
        this.tableData = this.originalData.filter((el) => el.targetType == this.relationType);
      }
    }
  }
  onChangeRelationType() {
    if (!this.originalData) {
    } else {
      this.tableData = this.originalData;

      if (!!this.relationType) {
        this.tableData = this.originalData.filter((el) => el.targetType == this.relationType);
      }
    }
  }
  setCols() {
    this.niceCols = [
      {
        name: 'Relations',
        key: 'relations',
        dataType: DataTypeEnum.relationNiceMode,
        options: { relationTypes: this.relationTypes },
        sortDisabled: true,
        passRowAsData: true,
      },
    ].map((x) => {
      return { ...x, name: this.localeService.translate(`modules.relation.fields.${x.key}.label`, x.name) };
    });
    this.cols = [
      {
        name: 'Id',
        key: 'targetCode',
        dataType: DataTypeEnum.CodeLink,
        filter: {
          type: 'text',
          matchMode: 'startsWith',
        },
      },
      {
        name: 'Target Type',
        key: 'targetType',
        dataType: DataTypeEnum.Badge,
        filter: {
          type: 'enum',
          display: 'menu',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          enumClass: RelationItemDto.TargetTypeEnum,
        },
      },
      {
        name: 'Relation Type',
        key: 'relationType',
        dataType: DataTypeEnum.RelationTypeCell,
        options: { relationTypes: this.relationTypes },
        filter: {
          type: 'text',
          matchMode: 'startsWith',
        },
      },
      {
        name: 'Relation Direction',
        key: 'relationDirection',
        dataType: DataTypeEnum.Badge,
        filter: {
          type: 'enum',
          display: 'menu',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          enumClass: RelationItemDto.RelationDirectionEnum,
        },
      },
      {
        name: 'Reference Field',
        key: 'fieldName',
        dataType: DataTypeEnum.HumanizedText,
        filter: {
          type: 'text',
          matchMode: 'startsWith',
        },
      },
      {
        name: 'Notes',
        key: 'notes',
        dataType: DataTypeEnum.LongWithHoverText,
        filter: {
          type: 'text',
          matchMode: 'startsWith',
        },
      },
      {
        name: 'Creator Name',
        key: 'creatorName',
        dataType: DataTypeEnum.UserListView,
        filter: {
          type: 'multiDropdown',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          dynamicInput: {
            componentType: UserSelectorComponent,
            options: {
              label: '',
              name: '',
              control: new FormControl(null),
            },
          },
        },
      },
      {
        name: 'Creation Date',
        key: 'creationDate',
        dataType: DataTypeEnum.DateLong,
        filter: {
          type: 'date',
          matchMode: 'startsWith',
        },
      },
    ].map((x) => {
      return { ...x, name: this.localeService.translate(`modules.relation.fields.${x.key}.label`, x.name) } as IColumn;
    });
  }

  deleteItem(row) {
    this.appDialogService.confirm({
      accept: () => {
        this.subs.sink = this.requestService.deleteRelation(this.sourceCode, row.id).subscribe({
          next: (res) => {
            // this.clearData();
            this.originalData = null;
            this.loadData();
          },
        });
      },
    });
  }

  initializeTableActions() {
    this.tableActions = [
      {
        id: 2,
        icon: 'pi pi-trash',
        buttonStyle: 'text',
        color: 'danger',
        command: this.deleteItem.bind(this),
        permission: `delete${this.moduleKeyword}`,
        displayCommand: (row: RelationItemDto) => row.relationType == 'MANUAL',
      },
    ];
  }
  clearData() {
    this.originalData = null;
    this.tableData = [];
    this.onLoadData.emit(this.originalData);
  }
}
