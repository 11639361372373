import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BaseFilter, IDynamicComponent } from '@shared/classes';
import { UserSelectorComponent } from '@shared/components/selectors/user-selector/user-selector.component';

@Component({
  selector: 'app-user-selector-filter',
  templateUrl: './user-selector-filter.component.html',
  styleUrls: ['./user-selector-filter.component.scss'],
})
export class UserSelectorFilterComponent extends BaseFilter implements OnInit {
  dropDownInput?: IDynamicComponent;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.initInput();
  }

  initInput() {
    this.dropDownInput = {
      componentType: UserSelectorComponent,
      options: {
        label: '',
        name: '',
        control: new FormControl(null),
        inputOptions: {},
      },
    };
  }
}
