import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseForm, IExportData } from '@shared/classes';
import { ViewModeService } from '@shared/services/view-mode.service';

@Component({
  selector: 'app-export-selected-data-form',
  templateUrl: './export-selected-data-form.component.html',
  styleUrls: ['./export-selected-data-form.component.scss'],
})
export class ExportSelectedDataFormComponent extends BaseForm<IExportData> implements OnInit {
  getData() {
    return this.formGroup.getRawValue();
  }

  setData(data: IExportData) {
    // if (!data) return;
    this.formGroup.patchValue({ ...data });
    this.data = data;
  }
  initFormStructure(): void {
    this.formGroup = new FormGroup({
      type: new FormControl('pdf', Validators.required),
    });
    this.submitButtonAction = {
      id: 1,
      label: 'Export',
      buttonType: 'button',
      command: this.onSubmitForm.bind(this),
      icon: 'pi pi-file-export',
      buttonClass: 'w-full',
      status$: this.formValid$,
    };
  }

  constructor(public viewModeService: ViewModeService) {
    super(viewModeService);
  }

  ngOnInit(): void {}
  onSubmitForm() {
    this.formSubmit.emit(this.getData());
  }
}
