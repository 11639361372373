<p-columnFilter
  [field]="col.key"
  [display]="col.filter.display || 'menu'"
  [placeholder]="col.filter.placeholder || 'Search By ' + col.name"
  [matchMode]="col.filter.matchMode"
  [showMatchModes]="false"
  [showOperator]="false"
  [showAddButton]="false"
  [showClearButton]="false"
  [showApplyButton]="false"
>
  <ng-template pTemplate="header">
    <div class="px-3 pt-3 pb-0">
      <span class="font-bold">{{ col.filter.placeholder || 'Filter By ' + col.name }}</span>
    </div>
  </ng-template>
  <ng-template pTemplate="filter" let-value let-filter="filterCallback">
    <p-selectButton
      [options]="buttonModeOptions"
      [ngModel]="value"
      optionLabel="label"
      optionValue="value"
      styleClass="view-switch-buttons"
      (ngModelChange)="buttonFilter = $event"
    >
      <ng-template let-item>
        <div
          class="flex flex-row gap-2 align-items-center w-full align-self-center justify-content-center"
          [pTooltip]="item.tooltip"
          tooltipPosition="top"
          style="padding: 0.629rem 0"
        >
          <i [class]="item.icon"></i>
          @if (item.label) {
            <div>
              {{ item.label }}
            </div>
          }
        </div>
      </ng-template>
    </p-selectButton>
    <div class="flex justify-content-between align-items-center mt-4">
      @if (col.filter.showClearButton ?? true) {
        <p-button
          label="Clear"
          styleClass="p-button-outlined"
          (onClick)="filter(undefined); buttonFilter = null"
        ></p-button>
      }
      @if (col.filter.showApplyButton ?? true) {
        <p-button label="Apply" (onClick)="filter(buttonFilter)"></p-button>
      }
    </div>
  </ng-template>
</p-columnFilter>
