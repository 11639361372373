import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BaseFilter, IDynamicComponent } from '@shared/classes';
import { TargetCodeSelectorComponent } from '@shared/components/selectors/target-code-selector/target-code-selector.component';
import { ColumnFilter } from 'primeng/table';

@Component({
  selector: 'app-relation-form-filter',
  templateUrl: './relation-form-filter.component.html',
  styleUrl: './relation-form-filter.component.scss',
})
export class RelationFormFilterComponent extends BaseFilter implements OnInit, AfterViewInit {
  dropDownInput?: IDynamicComponent;
  fControl = new FormControl(null);
  @ViewChild(ColumnFilter) columnFilterRef: ColumnFilter;
  visible = false;
  constructor() {
    super();
  }
  ngAfterViewInit(): void {
    this.columnFilterRef.toggleMenu = (): void => {
      this.visible = !this.visible;
      this.columnFilterRef.overlayVisible = this.visible;
    };
  }
  toggleMenu() {
    this.visible = !this.visible;
    this.columnFilterRef.overlayVisible = this.visible;
  }
  onToggleButtonKeyDown(event: KeyboardEvent) {
    switch (event.key) {
      case 'Escape':
      case 'Tab':
        this.visible = false;
        break;
      case 'Enter':
        this.toggleMenu();
        event.preventDefault();
        break;
    }
  }
  hasFilter() {
    return !!this.fControl?.value;
  }
  ngOnInit(): void {
    this.initInput();
  }

  initInput() {
    this.dropDownInput = {
      componentType: TargetCodeSelectorComponent,
      options: {
        label: '',
        name: '',
        control: new FormControl(null),
        inputOptions: {
          codeSelectorInput: {
            targetTypes: [this.col.filter.recordCodeType],
            extraFilters: this.col?.filter?.options?.extraFilters,
            // inlineMode:true,
          },
          dropDownInput: {
            optionLabel: 'label',
            optionValue: this.col?.filter?.options?.optionValue || 'code',
            multi: true,
            items: [],
          },
        },
      },
    };
  }
}
