import { Component, OnInit } from '@angular/core';
import { BaseFileViewerItem } from '@shared/classes';

@Component({
  selector: 'app-video-viewer',
  templateUrl: './video-viewer.component.html',
  styleUrl: './video-viewer.component.scss',
})
export class VideoViewerComponent extends BaseFileViewerItem implements OnInit {
  dataUrl: string | ArrayBuffer | null = '';
  constructor() {
    super();
  }
  ngOnInit(): void {}
  onSetData(): void {
    if (this.data) {
      this.loadBlob(this.data?.file);
    } else {
      this.dataUrl = null;
    }
  }
  loadBlob(blob: Blob) {
    const reader = new FileReader();

    // Once the file is loaded, the result will be a data URL
    reader.onload = () => {
      this.dataUrl = reader.result; // Store the video data URL here
    };

    // Read the blob as a data URL
    reader.readAsDataURL(blob);
  }
}
