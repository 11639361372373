import { HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BadgeColor, Base } from '@shared/classes';
import { FilesDataService } from 'app/modules/file-manager-module/services/files-data.service';
import { sortBy } from 'lodash-es';
import { PrimeIcons } from 'primeng/api';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'app-attachments-trail',
  templateUrl: './attachments-trail.component.html',
  styleUrls: ['./attachments-trail.component.scss'],
})
export class AttachmentsTrailComponent extends Base implements OnInit {
  events: any[];
  color = '#2ebae4';
  loading = true;
  colorMap = BadgeColor;
  icon = PrimeIcons.LINK;
  @Output() onAddItem: EventEmitter<any> = new EventEmitter();
  @Input()
  itemCode: String;
  @Input() autoFocus: boolean = false;
  documentUrl: string;
  documentName: string;
  progress: number;
  attachmentControl: FormControl = new FormControl(null);
  constructor(private documentService: FilesDataService) {
    super();
  }

  ngOnInit() {
    this.loadData();
  }

  private loadData() {
    this.loading = true;

    this.documentService
      .getAllAttachmentsByItemCode(this.itemCode)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.loading = false;
        this.events = sortBy(res.data, 'creationDate');
      });
  }
  onDeleteItem(event, index) {
    this.events.splice(index, 1);
    this.events = [...this.events];
  }

  onSubmit(data) {
    let body = {
      comment: data?.comment,
      sourceCode: this.itemCode,
    };

    this.loading = true;

    this.documentService
      .create(body)
      .pipe(takeUntil(this.destroy$))
      .subscribe((c) => {
        this.events.unshift(c.data);
        this.onAddItem.emit(c.data);
        this.loading = false;
      });
  }
  downloadItem(id: string) {
    this.documentService.downloadFileWithProgress(id).subscribe((event) => {
      if (event.type === HttpEventType.DownloadProgress) {
        this.progress = (event.loaded / event.total) * 100;
      }
      if (event.type === HttpEventType.Response) {
        const response = event;
        const data = URL.createObjectURL(new Blob([response.body], { type: response.body.type }));

        const contentDisposition = response.headers.get('content-disposition');

        if (contentDisposition != null) {
          const fileName = contentDisposition
            .split(';')[1]
            .split('filename')[1]
            .split('=')[1]
            .replace(/["]+/g, '')
            .trim();
          this.documentName = fileName;
        } else {
          this.documentName = 'file';
        }
        // const data = window.URL.createObjectURL(newBlob);

        let link = document.createElement('a');
        link.href = data;
        link.download = this.documentName;
        // this is necessary as link.click() does not work on the latest firefox
        link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
        this.progress = 100;
        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data);
          link.remove();
        }, 100);
      }
    });
  }
}
