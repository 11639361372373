import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { JWTTokenService } from '@core/services/JWT-token/jwttoken.service';
import { IAction } from '@shared/classes/view/ButtonAction';
import { SharedService } from '@shared/classes/view/sharedService';
import { ItemShareTrayComponent } from '@shared/components/components/item-share/item-share-tray/item-share-tray.component';
import { PathResolverService, ToastService } from '@shared/services';
import { AppDialogService } from '@shared/services/app-dialog.service';
import { LoaderService } from '@shared/services/loader.service';
import { ViewModeService } from '@shared/services/view-mode.service';
import { AppInjector } from 'app/app-injector';
import { AppBreadcrumbService } from 'app/app.breadcrumb.service';
import {
  AccessRecord,
  Base,
  BreadCrumb,
  IViewMode,
  MessageKeys,
  PermissionActions,
  TargetTypeEnum,
} from 'app/shared/classes';
import { cloneDeep } from 'lodash-es';
import { NgxPermissionsService } from 'ngx-permissions';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-base-page-item',
  templateUrl: './base-page-item.component.html',
  styleUrls: ['./base-page-item.component.scss'],
})
export class BasePageItemComponent extends Base implements OnInit {
  @Input()
  fromType: TargetTypeEnum;

  @Input() activeIndex: number = 0;

  showShareAction: IAction = {
    id: 1,
    label: 'Access',
    buttonType: 'button',
    icon: 'pi pi-user-plus',
    command: this.onAddSubscribers.bind(this),
    buttonClass: '',
    iconPos: 'left',
  };
  showCommentsAction: IAction = {
    id: 1,
    label: 'Comment',
    buttonType: 'button',
    icon: 'pi pi-comments',
    command: this.onShowComments.bind(this),
    buttonClass: '',
    iconPos: 'left',
  };
  showAttachmentsAction: IAction = {
    id: 1,
    label: 'Attachments',
    buttonType: 'button',
    icon: 'pi pi-link',
    command: this.onShowAttachments.bind(this),
    buttonClass: '',
    iconPos: 'left',
  };

  showAuditAction: IAction = {
    id: 1,
    label: 'Audit Trail',
    buttonType: 'button',
    color: 'info',
    icon: 'pi pi-list',
    command: this.onShowAudit.bind(this),
    buttonClass: '',
    iconPos: 'left',
  };
  showHistoryAction: IAction = {
    id: 1,
    label: 'Log',
    buttonType: 'button',
    color: 'primary',
    icon: 'pi pi-history',
    command: this.onShowHistory.bind(this),
    buttonClass: '',
    iconPos: 'left',
  };

  @Input() sourceCode: string;

  @Input() externalSourceCode: String;

  @Input() showRelations: boolean = true;
  @Input() showStateMachineAuditTrail: Subject<boolean>;
  @Input() showCommentsButton: boolean = true;
  @Input() showAddCommentButton: boolean = false;
  @Input() showShareButton: boolean = true;
  @Input() showAuditButton: boolean = false;
  @Input() showHistoryButton: boolean = false;
  @Input() hideTagsInput: boolean = false;
  @Input() withoutViewLoading: boolean = false;

  private _showViewSwitch: boolean = true;
  get showViewSwitch() {
    return this._showViewSwitch;
  }
  @Input()
  set showViewSwitch(showViewSwitch: boolean) {
    this._showViewSwitch = showViewSwitch;
    this.breadcrumbService.setShowViewModeSwitch(showViewSwitch);
  }

  private _breadCrumb: BreadCrumb = { items: [] };
  get breadCrumb() {
    return this._breadCrumb;
  }
  @Input() set breadCrumb(breadCrumb: BreadCrumb) {
    this._breadCrumb = breadCrumb;
    this.breadcrumbService.setItems(breadCrumb.items);
  }
  @Input() cardContainer: boolean = true;

  @Input() pageActions: IAction[] = [];
  @Input() pageActionsCommandData: any = null;
  private _moduleKeyword: string = null;
  get moduleKeyword() {
    return this._moduleKeyword;
  }
  @Input() set moduleKeyword(moduleKeyword: string) {
    this._moduleKeyword = moduleKeyword;
    this.showShareAction.permission = `${PermissionActions.Delete}${this.moduleKeyword}`;
    this.requestService = this.pathResolver.getDataServiceByModuleKeyword(this.moduleKeyword as any);
    this.breadcrumbService.setCurrentModuleKeyword(moduleKeyword);
  }
  requestService;
  tagsLoading = false;

  private _data: {
    id?: any;
    code?: string;
    lockedBy?: string;
    lockedUntil?: any;
    recordStatus?: any;
    label?: string;
    lastModificationDate?: string;
    lastModifierName?: string;
    creatorName?: string;
    creationDate?: string;
    userAccessLevel?: AccessRecord;
  } = null;
  get data() {
    return this._data;
  }
  @Input() set data(data: {
    id?: any;
    code?: string;
    lockedBy?: string;
    lockedUntil?: any;
    recordStatus?: any;
    label?: string;
    lastModificationDate?: string;
    lastModifierName?: string;
    creatorName?: string;
    creationDate?: string;
    userAccessLevel?: AccessRecord;
    tagsCodes?: Array<string>;
    tags?: Array<string>;
  }) {
    this._data = data;
    if (data) {
      const tagsData = data as any;
      if (tagsData.tagsCodes) {
        this.tagsControl.patchValue(tagsData.tagsCodes);
      }
      if (this.dataLoaded) this.refreshRelationsTable();
      this.dataLoaded = true;
      this.checkViewOptionsPermissions();
    }
  }
  @Input() hasEditChanges: boolean = false;
  @Input() recordStatusActions: IAction[] = [];
  @Input() extendLockTimeAction: IAction[] = [];
  @Input() showLocked: boolean = false;
  @Input() showRecordStatus: boolean = true;
  @Output() onViewModeChange: EventEmitter<IViewMode> = new EventEmitter();

  showAttachments: boolean = false;
  showComments: boolean = false;
  showAudit: boolean = false;
  showHistory: boolean = false;
  dataLoaded: boolean = false;

  @Input() showCountDown: boolean = true;

  pageViewMode: string = 'view';
  visibleSidebar5;
  editOption = { label: '', value: 'edit', icon: 'pi pi-pencil', tooltip: 'Edit' };
  viewOption = { label: '', value: 'view', icon: 'fa fa-file-text-o', tooltip: 'View' };
  viewModeOptions = [
    { label: '', value: 'view', icon: 'fa fa-file-text-o', tooltip: 'View' },
    { label: '', value: 'edit', icon: 'pi pi-pencil', tooltip: 'Edit' },
  ];
  private loadingState = new Subject<boolean>();
  loadingState$ = this.loadingState.asObservable();
  refreshRelations = false;
  tagsControl = new FormControl();
  sharedService: SharedService;
  constructor(
    private breadcrumbService: AppBreadcrumbService,
    public viewModeService: ViewModeService,
    private router: Router,
    private perms: NgxPermissionsService,
    private appDialogService: AppDialogService,
    private jWTTokenService: JWTTokenService,
    private pathResolver: PathResolverService,
    private toastService: ToastService
  ) {
    super();
    this.sharedService = AppInjector.get(SharedService);
    this.breadcrumbService.setShowViewModeSwitch(false);
    this.breadcrumbService.setShowTreeSwitch(false);
    this.viewModeService.viewModeState.pipe(takeUntil(this.destroy$)).subscribe((viewMode) => {
      this.pageViewMode = viewMode;
    });
  }
  ngOnInit(): void {
    const loaderService = AppInjector.get(LoaderService);
    loaderService.loadingState.pipe(takeUntil(this.destroy$)).subscribe((loadingState) => {
      this.loadingState.next(loadingState);
    });

    this.showStateMachineAuditTrail?.pipe(takeUntil(this.destroy$)).subscribe((showStateMachineAuditTrailState) => {
      if (showStateMachineAuditTrailState) {
        if (this.showComments) {
          this.activeIndex = 2;
        } else if (this.showAttachments) {
          this.activeIndex = 4;
        } else {
          this.activeIndex = 3;
        }
      }
    });
  }
  onChangeViewMode(mode: { event?: any; value?: IViewMode }) {
    if (this.hasEditChanges && mode.value == 'view') {
      setTimeout(() => {
        this.pageViewMode = 'edit';
      }, 10);
      this.appDialogService.confirm(
        {
          accept: () => {
            this.pageViewMode = 'view';
            this.onViewModeChange.emit(mode.value);
          },
        },
        { message: 'Any Changes Will be lost, Proceed?' }
      );
    } else {
      this.onViewModeChange.emit(mode.value);
    }
  }
  checkViewOptionsPermissions() {
    if (this.moduleKeyword) {
      let options = [];
      this.perms.hasPermission(PermissionActions.Update + this.moduleKeyword).then((res) => {
        if (
          res &&
          (this.jWTTokenService?.decodedToken?.sf_sys_admin ||
            this.data?.userAccessLevel?.accessLevel != AccessRecord.AccessLevelEnum.Read)
        )
          options.push(this.editOption);
        this.viewModeOptions = [...options];
        if (options.length) {
          this.perms.hasPermission(PermissionActions.Read + this.moduleKeyword).then((res) => {
            if (res) options.push(this.viewOption);
            this.viewModeOptions = [...options];
          });
        }
      });

      this.viewModeOptions = [...options];
    }
  }

  onShowComments() {
    this.showComments = true;
    this.activeIndex = 2;
    this.showStateMachineAuditTrail.next(true);
  }
  onShowAttachments() {
    this.showAttachments = true;
    this.activeIndex = 4;
    this.showStateMachineAuditTrail.next(true);
  }

  closeComments() {
    this.showComments = false;
  }

  closeStateMachineAuditTrail() {
    this.showStateMachineAuditTrail.next(false);
    this.activeIndex = 0;
    this.showComments = false;
  }

  onShowAudit() {
    this.showAudit = true;
  }

  closeAudit() {
    this.showAudit = false;
  }

  onShowHistory() {
    this.showHistory = true;
    this.activeIndex = 0;
  }
  closeHistory() {
    this.showHistory = false;
    this.showComments = false;
    this.showStateMachineAuditTrail.next(false);
    this.activeIndex = 0;
  }
  onCountDownEnd(event: { countDownOver: boolean }) {
    if (event.countDownOver && this.data && this.pageViewMode != 'view') {
      this.showCountDown = false;
      this.extendLockTimeAction?.[0]?.command(this.data);
    }
  }
  onCountDownConditionalTimeMet(event: { conditionalTimeMet: boolean }) {
    if (event.conditionalTimeMet && this.data && this.pageViewMode != 'view') {
      this.visibleSidebar5 = true;
    }
  }
  onAddSubscribers() {
    this.appDialogService.showDialog(
      ItemShareTrayComponent,
      'Manage Access',
      (e) => {
        if (e) {
          // this.requestService.addWatchers(this.itemId,e.people).subscribe(res=>{
          // })
        }
      },
      {
        data: {
          itemId: this.data?.code,
        },
        width: '75%',
      }
    );
  }
  refreshRelationsTable() {
    this.refreshRelations = true;
    setTimeout(() => {
      this.refreshRelations = false;
    }, 10);
  }
  onTagsEditSubmit(event) {
    event;
    if (this.requestService) {
      this.tagsLoading = true;
      const tempData = cloneDeep(this.data as any);
      if (this.pageViewMode == 'create') {
        this.tagsControl.patchValue(event.tagCodes);
        this.data = { ...this.data, tagsCodes: event?.tagCodes, tags: event?.tagStrings };
        this.sharedService.data = cloneDeep(this.data);
        setTimeout(() => {
          this.tagsLoading = false;
        }, 100);
      } else if (this.pageViewMode == 'edit') {
        this.requestService
          .patchSingleUpdate(
            [
              {
                key: 'tagsCodes',
                value: event?.tagCodes,
              },
            ],
            (tempData as any).code
          )
          .subscribe({
            next: (res) => {
              this.tagsControl.patchValue(event.tagCodes);
              tempData.tags = event?.tagStrings;
              this.data = cloneDeep(tempData);
              this.sharedService.data = cloneDeep(this.data);
              this.tagsLoading = false;
              this.toastService.success(MessageKeys.success, 'Tags Updated Successfully.');
            },
            error: () => {
              this.data = cloneDeep(tempData);
              this.tagsLoading = false;
            },
            complete: () => {
              this.data = cloneDeep(tempData);
              this.tagsLoading = false;
            },
          });
      }
    }
  }
}
