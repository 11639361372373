<div class="grid">
  <div class="col-12">
    <div>
      <div
        class="flex justify-content-between"
        [class.flex-row-reverse]="pageActions.length > 0"
        [class.mb-2]="pageActions.length > 0"
      >
        @if (pageActions.length > 0) {
          <app-button-list
            class="align-self-center"
            [listType]="'split'"
            [actionList]="pageActions"
            [commandData]="pageActionsCommandData"
          ></app-button-list>
        }
        <ng-content select="[page-header]"></ng-content>
      </div>
      <!-- Offline Change event not needed for the sake of route changes consistency and not cause double requests -->
      <app-table
        [cols]="niceMode ? niceCols : cols"
        [selectionMode]="null"
        [tableActions]="tableActions"
        [actionCol]="actionCol"
        [inputData]="tableData"
        [offlineConfig]="offlineConfig"
        (filtersChanged)="onFilterChange($event)"
        [pageInfo]="tableEvent.pageInfo"
        (onExportData)="loadExportData($event)"
        (onFixRelationAction)="onFixRelations($event)"
        [isLoading]="isLoading"
      ></app-table>
      <ng-content select="[page-footer]"></ng-content>
    </div>
  </div>
</div>
