import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BadgeColor, BadgeIcon, BaseViewItem, isNullObj } from '@shared/classes';
import { CodeLabelCacheService } from '@shared/services/hub-services/code-label-cache.service';
import { PathResolverService } from '@shared/services/path-resolver.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-code-navigation',
  templateUrl: './code-navigation.component.html',
  styleUrls: ['./code-navigation.component.scss'],
})
export class CodeNavigationComponent extends BaseViewItem implements OnInit {
  constructor(
    private pathResolverService: PathResolverService,
    private codeCacheService: CodeLabelCacheService
  ) {
    super();
  }
  badgeIcons = BadgeIcon;
  badgeColors = BadgeColor;
  @Input() color: string = null;
  @Input() showRecordStatus: boolean = true;
  @Input() showLabel: boolean = true;
  @Input() showTooltip: boolean = false;
  @Input() sendRequest: boolean = true;
  @Input() badgeMode: boolean = false;
  @Input() staticData: { code?: string; label?: string; recordStatus?: any } = null;
  @Output() onSetLabel: EventEmitter<any> = new EventEmitter();
  hrefPath: string = '';
  label = null;
  labelSubject: BehaviorSubject<any>;
  loadingLabel: boolean = false;
  ngOnInit(): void {}
  onSetData(): void {
    this.clearData();
    if (!this.data) return;
    this.hrefPath = this.pathResolverService.getPathByCode(this.data?.code || this.data?.name || this.data);
    if (this.sendRequest) {
      this.labelSubject = this.codeCacheService.addCode(
        this.data?.code || this.data?.name || this.data,
        this.data,
        this.staticData
      );
      this.labelSubject.subscribe((res) => {
        this.label = res;
        this.loadingLabel = res.loading === true;
        if (this.label?.label) {
          this.onSetLabel.emit(this.label);
        }
      });
    } else {
      if (!isNullObj(this.staticData)) {
        this.label = { ...this.staticData };
      }
    }
  }
  get code() {
    return this.data?.code || this.data?.name || this.data;
  }
  clearData() {
    this.hrefPath = '';
    this.label = null;
    this.labelSubject = null;
    this.loadingLabel = false;
  }
}
