<!-- <p-columnFilter
  [field]="col.key"
  [display]="col.filter.display || 'menu'"
  [placeholder]="col.filter.placeholder || 'Search By ' + col.name"
  [matchMode]="col.filter.matchMode"
  [showMatchModes]="col.filter.showMatchModes ?? true"
  [showOperator]="col.filter.showOperator ?? true"
  [showAddButton]="false"
  [showClearButton]="false"
  [showApplyButton]="false"
>
  <ng-template pTemplate="header">
    <div class="px-3 pt-3 pb-0">
      <span class="font-bold">{{ col.filter.placeholder || 'Search By ' + col.name }}</span>
    </div>
  </ng-template>
</p-columnFilter> -->
<button
  #icon
  type="button"
  class="p-column-filter-menu-button p-link"
  aria-haspopup="true"
  [ngClass]="{ 'p-column-filter-menu-button-open': visible, 'p-column-filter-menu-button-active': hasFilter() }"
  (click)="toggleMenu()"
  (keydown)="onToggleButtonKeyDown($event)"
>
  <i class="pi-filter-icon pi pi-filter"></i>
</button>
<p-dialog
  header="Edit Profile"
  [modal]="true"
  [(visible)]="visible"
  [style]="{ width: '60%', height: '90%', zIndex: 999999 }"
  [draggable]="false"
  [baseZIndex]="9999999"
>
  <p-columnFilter
    [field]="col.key"
    [display]="'row'"
    [showMenu]="false"
    [placeholder]="col.filter.placeholder || 'Search By ' + col.name"
    [matchMode]="col.filter.matchMode"
    [showMatchModes]="col.filter.showMatchModes ?? true"
    [showOperator]="col.filter.showOperator ?? true"
    [showAddButton]="false"
    [showClearButton]="false"
    [showApplyButton]="false"
    style="cursor: auto"
  >
    <ng-template pTemplate="header">
      <div class="px-3 pt-3 pb-0">
        <span class="font-bold">{{ col.filter.placeholder || 'Search By ' + col.name }}</span>
      </div>
    </ng-template>
    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
      <app-relation-form-filter-control
        [visible]="visible"
        [control]="fControl"
        [viewMode]="'edit'"
        [formControl]="fControl"
        [manualValue]="value"
        style="display: contents"
      >
      </app-relation-form-filter-control>
      <div class="flex justify-content-between align-items-center mt-4">
        @if (col.filter.showClearButton ?? true) {
          <p-button
            label="Clear"
            styleClass="p-button-outlined"
            (onClick)="filter(undefined); fControl.patchValue(null)"
          ></p-button>
        }
        @if (col.filter.showApplyButton ?? true) {
          <p-button label="Apply" (onClick)="filter(fControl.value); toggleMenu()"></p-button>
        }
      </div>
    </ng-template>
  </p-columnFilter>
</p-dialog>
