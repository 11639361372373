@switch (fileViewerType) {
    @case (fileViewerTypes.IMAGE) {
        <app-image-viewer [data]="data"></app-image-viewer>
    }
    @case (fileViewerTypes.PDF) {
        <app-pdf-viewer [data]="data"></app-pdf-viewer>
    }
    @case (fileViewerTypes.TEXT) {
        <app-text-viewer [data]="data"></app-text-viewer>
    }
    @case (fileViewerTypes.AUDIO) {
        <app-audio-viewer [data]="data"></app-audio-viewer>
    }
    @case (fileViewerTypes.VIDEO) {
        <app-video-viewer [data]="data"></app-video-viewer>
    }
    @default{
        No Viewer Available
    }
}