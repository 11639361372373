import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-side-bar-audit-trail',
  templateUrl: './side-bar-audit-trail.component.html',
  styleUrls: ['./side-bar-audit-trail.component.scss'],
})
export class SideBarAuditTrailComponent implements OnInit {
  events: any[];

  @Input()
  display: boolean = true;

  @Output()
  onCloseComments: EventEmitter<boolean> = new EventEmitter();

  @Input()
  itemCode: String;

  ngOnInit() {}

  onHide() {
    this.onCloseComments.emit(true);
  }
}
