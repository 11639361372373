@if (loading) {
  <div class="flex justify-content-center align-items-center w-full">
    <!-- <p-progressSpinner></p-progressSpinner> -->
    <ul class="m-0 p-0 list-none w-full">
      <li class="mb-3">
        <div class="flex w-full">
          <p-skeleton shape="circle" size="2rem" styleClass="mr-3"></p-skeleton>
          <div style="flex: 1">
            <p-skeleton width="100%" height="7rem"></p-skeleton>
          </div>
        </div>
      </li>
    </ul>
  </div>
}
<!-- <div *ngIf="!loading && events?.length > 0" class="history-trail gap-2">
<div class="history-item gap-3" *ngFor="let item of events">
  <div class="user-info gap-2">
    <app-user-list-view [size]="'large'" [data]="item.createdUser ? [item.createdUser] : ['No User']"></app-user-list-view>
    <div class="flex flex-column gap-2">
      <div class="user-name">{{item.createdUser}}</div>
      <div class="w-full flex flex-row gap-1">
        <i [ngClass]="iconMap[item.type]"></i>
        <div class="action-text">{{item.actionTitle | titlecase}}</div>
        <div class="" timeago [date]="item.creationDate | date : 'yyyy-MM-dd HH:mm:ss'"></div>
      </div>
    </div>
  </div>
  <div class="action-info">
    <div *ngIf="item.extraData.length == 1" class="affected-fields overflow-auto max-h-12rem">
      <div class="field-row" *ngFor="let field of item.extraData; let isLast=last">
        <div class="field-name">{{field.fieldName}}</div>
        <div class="field-value-row">
          <div class="empty-circle-row">
            <div class="empty-circle" [ngClass]="{'hidden': field.oldValue === null}"></div>
            <div class="empty-value" [ngClass]="{'hidden': field.oldValue === null}">{{field.oldValue}}</div>
          </div>
          <div class="filled-circle-row">
            <div class="filled-circle" [ngClass]="{'hidden': field.newValue === null}"></div>
            <div class="filled-value" [ngClass]="{'hidden': field.newValue === null}">{{field.newValue}}</div>
          </div>
        </div>
        <div class="field-value-row" [ngClass]="{'hidden': isLast}"></div>
      </div>
    </div>
    <p-panel *ngIf="item.extraData.length > 1" [header]="item.extraData.length > 1 ? 'Affected Fields' : item.extraData[0].fieldName"
      [toggleable]="item.extraData.length > 1" [collapsed]="true"
      collapseIcon="pi pi-angle-up"
      expandIcon="pi pi-angle-down"
      >
      <div class="affected-fields overflow-auto max-h-12rem">
        <div class="field-row" *ngFor="let field of item.extraData">
          <div class="field-name">{{field.fieldName}}</div>
          <div class="field-value-row">
            <div class="empty-circle-row">
              <div class="empty-circle" [ngClass]="{'hidden': field.oldValue === null}"></div>
              <div class="empty-value" [ngClass]="{'hidden': field.oldValue === null}">{{field.oldValue}}</div>
            </div>
            <div class="filled-circle-row">
              <div class="filled-circle" [ngClass]="{'hidden': field.newValue === null}"></div>
              <div class="filled-value" [ngClass]="{'hidden': field.newValue === null}">{{field.newValue}}</div>
            </div>
          </div>
        </div>
      </div>
    </p-panel>

    <div>
      <p [innerHTML]="item.comment">
      </p>
    </div>
  </div>
</div>
</div> -->

@if (!loading && events?.length > 0) {
  <p-timeline class="trail-timeline" [value]="events" align="left">
    <ng-template pTemplate="marker" let-event>
      <span [class]="'custom-marker shadow-2 ' + 'bg-' + (colorMap['TRAIL_' + event.type] || 'gray') + '-500 '">
        <i [ngClass]="iconMap?.[event?.type] || 'pi pi-question'"></i>
      </span>
    </ng-template>
    <ng-template pTemplate="content" let-event>
      <app-audit-trail-card [hideCode]="true" [data]="event"></app-audit-trail-card>
    </ng-template>
  </p-timeline>
}

@if (!loading && !!events && events?.length == 0) {
  <div class="flex justify-content-center align-items-center w-full">
    <span class="font-medium text-base text-center w-full">
      No items found <i [class]="eventsFilters?.length > 0 ? 'fa-solid fa-sitemap' : 'pi pi-list'"></i>
    </span>
  </div>
}
