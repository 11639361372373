<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
  <div class="formgrid grid p-fluid gap-4 mt-3 w-full m-auto">
    <div class="w-full">
      <app-drop-down-input
        [label]="'Export As'"
        [placeholder]="'Select Export Type'"
        [name]="'type-dropdown'"
        [optionLabel]="'name'"
        [items]="[
          { name: 'PDF', value: 'pdf' },
          { name: 'Excel', value: 'xls' },
          { name: 'CSV', value: 'csv' },
        ]"
        [optionValue]="'value'"
        [control]="formGroup?.controls?.type"
      >
      </app-drop-down-input>
    </div>
  </div>
  <div class="w-full">
    <app-button [action]="submitButtonAction"></app-button>
  </div>
</form>
