<div class="flex flex-row gap-1">
  @if (!isLoading) {
    <i [class]="'h-3rem w-2rem min-w-2rem fiv-viv fiv-icon-' + documentExtension"> </i>
  } @else {
    <p-skeleton width="6rem" height="5rem" styleClass=""></p-skeleton>
  }
  <div class="flex flex-row justify-content-between relative min-w-0 align-items-center w-full">
    <div class="w-full flex flex-row text-700 gap-2 align-items-center min-w-0">
      @if (isLoading) {
        <p-skeleton width="100%" height="1rem" styleClass="mb-2"></p-skeleton>
      }
      @if (!isLoading) {
        <app-text-view [data]="documentName" [useStaticMaxWidth]="false" class="text-2xl"></app-text-view>
        <app-date-view
          [useTimeAgo]="true"
          [data]="documentData?.creationDate"
          style="text-wrap: nowrap"
        ></app-date-view>
      }
    </div>
    <div class="flex flex-row gap-1">
      @if (!inProgress && documentUrl) {
        <app-button
          [action]="downloadAttachmentAction"
          [pTooltip]="'Download ' + (documentData?.size | humanizeFileSize)"
          [tooltipPosition]="'top'"
        ></app-button>
      }
      @if (!inProgress && documentUrl && showDeleteButton) {
        <app-button [action]="deleteAttachmentAction"></app-button>
      }
      @if (inProgress) {
        <app-button [action]="cancelRequestAction"></app-button>
      }
    </div>
  </div>
</div>
@if (data) {
  <app-file-viewer-switch [data]="data"></app-file-viewer-switch>
}
@if (inProgress) {
  <div class="w-12 flex flex-column gap-2 mt-1">
    <p>
      File is Downloading Please Wait... <span>{{ progress | parseToFixedNumber: 'int' }}%</span>
    </p>
    <app-progressbar-view [bgColor]="progressBg" [data]="progress"></app-progressbar-view>
  </div>
}
