import { Component, OnInit } from '@angular/core';
import { BaseFileViewerItem } from '@shared/classes';

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrl: './pdf-viewer.component.scss',
})
export class PdfViewerComponent extends BaseFileViewerItem implements OnInit {
  objectUrl: string;
  constructor() {
    super();
  }
  ngOnInit(): void {}
  onSetData(): void {
    if (this.data?.file) {
      this.objectUrl = URL.createObjectURL(this.data?.file);
    }
  }
  onDestroy(): void {
    window?.URL?.revokeObjectURL(this.objectUrl);
  }
}
