import { Component, Input, OnInit } from '@angular/core';
import { AuditTrailDto, DataItem, DataTypeEnum, IFieldDefinition, isNullObj } from '@shared/classes';
import { BaseViewItem } from '@shared/classes/view/BaseViewItem';
import { AuditTrailService } from '@shared/services/audit-trail-service/relations.service';
import { PathResolverService } from '@shared/services/path-resolver.service';

@Component({
  selector: 'app-audit-trail-card',
  templateUrl: './audit-trail-card.component.html',
  styleUrls: ['./audit-trail-card.component.scss'],
})
export class AuditTrailCardComponent extends BaseViewItem<AuditTrailDto> implements OnInit {
  mappedFields: {
    [key: string]: IFieldDefinition;
  };

  @Input()
  hideCode: boolean = false;

  dataTypes = DataTypeEnum;

  constructor(
    private auditTrailService: AuditTrailService,
    private pathResolverService: PathResolverService
  ) {
    super();
  }

  checkType(dataType: DataTypeEnum) {
    return this.auditTrailService.checkType(dataType);
  }

  checkHiddenLable(lable: string) {
    return this.auditTrailService.checkHideLable(lable);
  }

  checkCollapsedType(type) {
    return type === AuditTrailDto.TypeEnum.Created || type === AuditTrailDto.TypeEnum.Updated;
  }
  ngOnInit() {
    this.mappedFields = this.pathResolverService.getMappingServiceByModuleKeyword(
      this.pathResolverService.getModuleKeywordByCode(this.data.targetCode)
    ).mappedFields;
    this.checkSpecialFields();
  }

  public checkSpecialFields() {
    let parsedData = new Set<DataItem>();

    this.data.extraData.forEach((x) => {
      try {
        if (this.auditTrailService.checkRemoveFields(x.fieldName ?? '')) {
          parsedData.add({
            fieldName: x.fieldName,
            oldValue: isNullObj(x.oldValue)
              ? null
              : this.checkNonJsonParsableField(x.fieldName)
                ? x.oldValue
                : JSON.parse(x.oldValue),
            newValue: isNullObj(x.newValue)
              ? null
              : this.checkNonJsonParsableField(x.fieldName)
                ? x.newValue
                : JSON.parse(x.newValue),
          });
        } else {
          this.data.version = parseInt(x.newValue ?? x.oldValue ?? '0');
        }
      } catch (e) {
        try {
          console.warn('Error parsing field : ', x, DataTypeEnum[this.mappedFields[x.fieldName].dataType]);
        } catch (ex) {
          console.warn('Error parsing field : ', x, 'no Mapper');
        }
        parsedData.add({
          fieldName: x.fieldName,
          oldValue: x.oldValue,
          newValue: x.newValue,
        });
      }
      this.data.extraData = new Set<DataItem>([...parsedData]);
    });

    let newExtraData = new Set<DataItem>();

    this.data.extraData.forEach((x) => {
      if (this.mappedFields[x.fieldName]) {
        if (this.mappedFields[x.fieldName].dataType === DataTypeEnum.AuditTrailDynamics) {
          this.processDynamicsData(x, newExtraData);
        }
        // Update Results with New Extra Data
        this.data.extraData = new Set<DataItem>([...this.data.extraData, ...newExtraData]);
      }
    });
  }

  processDynamicsData(x: any, newExtraData: Set<DataItem>): void {
    try {
      this.data.extraData = new Set<DataItem>([...this.data.extraData].filter((v) => v.fieldName !== x.fieldName));

      const dynamicsNew = isNullObj(x.newValue) ? {} : JSON.parse(x.newValue);
      const dynamicsOld = isNullObj(x.oldValue) ? {} : JSON.parse(x.oldValue);
      let dNewEntries = Object.entries(dynamicsNew);
      let dOldEntries = Object.entries(dynamicsOld);
      let newMap: Map<any, any> = new Map<any, any>();
      let oldMap: Map<any, any> = new Map<any, any>();

      // Setting up the maps
      dNewEntries.forEach((val) => {
        newMap.set(val[0], val);
      });

      dOldEntries.forEach((val) => {
        oldMap.set(val[0], val);
      });

      // Construct created
      dNewEntries.forEach((val) => {
        const obj: any = val[1];

        if (!oldMap.has(val[0])) {
          newExtraData.add({
            fieldName: val[0] + '‎',
            oldValue: '',
            newValue: obj?.value ?? '',
          });
        }
      });

      // Construct Updated
      dOldEntries.forEach((val) => {
        const obj: any = val[1];
        if (newMap.has(val[0]) && newMap.get(val[0])[1].value != obj.value) {
          newExtraData.add({
            fieldName: val[0] + '‎',
            oldValue: obj?.value ?? '',
            newValue: newMap.get(val[0])[1].value ?? '',
          });
        } else if (!newMap.has(val[0])) {
          newExtraData.add({
            fieldName: val[0] + '‎',
            oldValue: obj?.value ?? '',
            newValue: '',
          });
        }
      });
    } catch (e) {}
  }

  checkNonJsonParsableField(fieldName: string) {
    try {
      if (fieldName == 'members') return false; //@TODO: remove when backend fixes the object
      const dt = this.mappedFields?.[fieldName]?.dataType;
      const allowedTypes = [
        this.dataTypes.Text,
        this.dataTypes.LongText,
        this.dataTypes.Date,
        this.dataTypes.DateLong,
        this.dataTypes.Badge,
        this.dataTypes.UserListView,
        this.dataTypes.CodeWithStatus,
        this.dataTypes.CodeLink,
      ];
      return allowedTypes?.includes(dt);
    } catch (e) {
      return true;
    }
  }
}
