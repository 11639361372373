import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BaseForm, IAction } from '@shared/classes';
import { AppDialogService, ViewModeService } from '@shared/services';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-custom-page-popup',
  templateUrl: './custom-page-popup.component.html',
  styleUrl: './custom-page-popup.component.scss'
})
export class CustomPagePopupComponent extends BaseForm<any> implements OnInit {

  tagsStringControl = new FormControl(null);
  tabItems = [];
  searchItems = [];
  loading = false;

  saveButton: IAction = {
    id: 1,
    label: 'Save',
    buttonType: 'button',
    command: this.onSubmit.bind(this),
    icon: 'pi pi-save',

  };

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private appDialogService: AppDialogService,
    public viewModeService: ViewModeService,
  ) {
    super(viewModeService);
    if (!this.data) this.data = {};
    this.formData = this.data;
    config.width = '20rem';
  }
  ngOnInit(): void {
  }
  getData() {
    return this.data;
  }
  setData(data: any) {
  }
  initFormStructure(): void {
    this.formGroup = new FormGroup({
      page: new FormControl(1),
    });
  }

  onSubmit() {
    this.ref.close(this.formGroup?.controls?.page?.value ?? 1);
  }

}
