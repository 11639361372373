@if (visible) {
  <div class="col-12 md:col-12">
    <app-target-code-selector
      [label]="''"
      [placeholder]="'Enter Relation Type'"
      [control]="formGroup?.controls?.relationsTypes"
      [viewMode]="'create'"
      [multi]="true"
      [optionLabel]="'name'"
      [optionValue]="'name'"
      [showStatusBadge]="false"
      [customProjectionFields]="['code', 'recordStatus', 'label', 'name', 'color', 'icon']"
      [badgeView]="true"
      [useCustomBadgeMode]="true"
      [targetTypes]="[relationTypeTargetType]"
      [inlineMode]="true"
      [listBoxMode]="false"
      [appendTo]="'body'"
    >
    </app-target-code-selector>
  </div>
  <div class="col-12 md:col-12">
    <app-drop-down-input
      [label]="''"
      [placeholder]="'Relation Directions'"
      [name]="'dropdown'"
      [optionLabel]="'label'"
      [items]="relationDirectionOptions"
      [optionValue]="'value'"
      [control]="formGroup?.controls?.relationDirections"
      [badgeView]="true"
      [multi]="true"
      [appendTo]="'body'"
    ></app-drop-down-input>
  </div>
  <div class="col-12 md:col-12">
    <app-drop-down-input
      [label]="''"
      [placeholder]="'Target Types'"
      [name]="'dropdown'"
      [optionLabel]="'label'"
      [items]="listOfTypes"
      [optionValue]="'value'"
      [control]="uiFormGroup?.controls?.targetTypes"
      [badgeView]="true"
      [multi]="true"
      [appendTo]="'body'"
    ></app-drop-down-input>
  </div>
  @if (uiFormGroup?.controls?.targetTypes?.value) {
    <div class="col-12 md:col-12">
      <app-target-code-selector
        [label]="'Target Elements'"
        [placeholder]="'Target Elements'"
        [control]="formGroup?.controls?.codes"
        [viewMode]="'create'"
        [multi]="true"
        [targetTypes]="uiFormGroup?.controls?.targetTypes?.value"
        [listBoxMode]="true"
        [inlineMode]="true"
        [optionValue]="'code'"
      >
      </app-target-code-selector>
    </div>
  }
}
