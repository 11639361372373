<div class="relative" [style]="{ height: height }">
  @if (isLoadingEditor || reloadEditor) {
    <div class="absolute top-0 left-0 w-full h-full" style="z-index: 100">
      <p-skeleton width="100%" [height]="height" styleClass=""></p-skeleton>
    </div>
  }
  @if (!reloadEditor) {
    <ngx-monaco-editor
      [options]="editorOptions"
      (onContentChange)="onChange($event)"
      (onInit)="onInit($event)"
      [(ngModel)]="code"
      [style]="{ height: height }"
    ></ngx-monaco-editor>
  }
</div>
