import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-count-down-view',
  templateUrl: './count-down-view.component.html',
  styleUrls: ['./count-down-view.component.scss'],
})
export class CountDownViewComponent implements OnInit, OnDestroy {
  private _date: Date;
  get date() {
    return this._date;
  }
  @Input() set date(date: Date) {
    if (date) {
      this._date = new Date(date as any);
      this.timerDate = Math.trunc(new Date(this.date).getTime() / 1000);
      this.now = Math.trunc(new Date().getTime() / 1000);
      this.conditionalTimeEventTriggered = false;
    }
  }
  @Input() showTimeText: boolean = false;
  @Input() showConditionalContentTimeDiff: number = 1.5; //@Note: time in mins 1.5 = 90 seconds;
  @Input() badgeView: boolean = false;
  @Input() badgeType: 'solid' | 'outline' | 'custom' = 'outline';
  @Input() badgeText: string = null;
  @Input() displayText: boolean = true;
  @Output() onCountDownEnd: EventEmitter<{ countDownOver: boolean }> = new EventEmitter();
  @Output() onCountDownConditionalTimeMet: EventEmitter<{ conditionalTimeMet: boolean }> = new EventEmitter();

  public timerDate;
  public now;
  public showTimerStatic: boolean = true;
  private intervalId;
  private conditionalTimeEventTriggered: boolean = false;
  constructor() {
    this.intervalId = setInterval(() => {
      this.now = Math.trunc(new Date().getTime() / 1000);
    }, 1000);
    this.conditionalTimeEventTriggered = false;
  }

  ngOnInit(): void {}
  ngOnDestroy(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }
  get showConditionalContent() {
    let numb = (this.timerDate - this.now) / 60;
    return numb <= this.showConditionalContentTimeDiff;
  }
  get showTimer() {
    this.showTimerStatic = this.timerDate - this.now > 0;
    if (!this.showTimerStatic) this.onCountDownEnd.emit({ countDownOver: !this.showTimerStatic });
    let numb = (this.timerDate - this.now) / 60;
    if (numb <= this.showConditionalContentTimeDiff && !this.conditionalTimeEventTriggered) {
      this.onCountDownConditionalTimeMet.emit({ conditionalTimeMet: true });
      this.conditionalTimeEventTriggered = true;
    }
    return this.showTimerStatic;
  }
  get seconds() {
    return (this.timerDate - this.now) % 60;
  }

  get minutes() {
    return Math.trunc((this.timerDate - this.now) / 60) % 60;
  }

  get hours() {
    return Math.trunc((this.timerDate - this.now) / 60 / 60) % 24;
  }

  get days() {
    return Math.trunc((this.timerDate - this.now) / 60 / 60 / 24);
  }
}
