import { Component, OnInit } from '@angular/core';
import { BaseFileViewerItem } from '@shared/classes';

@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrl: './image-viewer.component.scss'
})
export class ImageViewerComponent extends BaseFileViewerItem implements OnInit {
  imageUrl: string | ArrayBuffer | null = '';
  constructor() {
    super();
  }
  ngOnInit(): void {}
  onSetData(): void {
    if(this.data){
      this.loadImageBlob();
    }else{
      this.imageUrl = null;
    }
  }
  loadImageBlob() {
    const reader = new FileReader();
    
    // Once the file is loaded, the result will be a data URL (base64 encoded image)
    reader.onload = () => {
      this.imageUrl = reader.result;  // Store the image data URL here
    };

    // Read the blob as a data URL
    reader.readAsDataURL(this.data?.file);
  }
}