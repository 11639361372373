<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
  <div class="formgrid grid p-fluid gap-4 mt-3 w-full m-auto">
    <div class="w-full">
      <app-number-input
        [label]="'Records Number'"
        [placeholder]="'50'"
        [name]="'records-number'"
        [showButtons]="true"
        [control]="formGroup?.controls?.size"
        [type]="'number'"
        [maxFractionDigits]="0"
        [min]="1"
        [max]="totalElements"
      ></app-number-input>
    </div>
    <div class="w-full">
      <!-- <app-number-input [label]="'Page Number'" [placeholder]="'0'" [name]="'page-number'" [showButtons]="true"

                [control]="formGroup?.controls?.page" [type]="'number'" [maxFractionDigits]="0" [min]="0"></app-number-input> -->
      <app-drop-down-input
        [label]="'Range'"
        [control]="formGroup?.controls?.page"
        [items]="pageList"
        [name]="'dropdown'"
        [optionLabel]="'label'"
        [optionValue]="'value'"
        [multi]="false"
        [appendTo]="'body'"
      >
      </app-drop-down-input>
    </div>
    <div class="w-full">
      <app-drop-down-input
        [label]="'Export As'"
        [placeholder]="'Select Export Type'"
        [name]="'type-dropdown'"
        [optionLabel]="'name'"
        [items]="[
          { name: 'PDF', value: 'pdf' },
          { name: 'Excel', value: 'xls' },
          { name: 'CSV', value: 'csv' },
        ]"
        [optionValue]="'value'"
        [control]="formGroup?.controls?.type"
        [appendTo]="'body'"
      >
      </app-drop-down-input>
    </div>
  </div>
  <div class="w-full">
    <app-button [action]="submitButtonAction"></app-button>
  </div>
</form>
