<p-columnFilter
  [type]="col.filter.type"
  [field]="col?.filter?.extraKey ? col.key + col?.filter?.extraKey : col.key"
  [display]="col.filter.display || 'menu'"
  [placeholder]="col.filter.placeholder || 'Search By ' + col.name"
  [matchMode]="col.filter.matchMode"
  [showMatchModes]="col.filter.showMatchModes ?? true"
  [showOperator]="col.filter.showOperator ?? true"
  [showAddButton]="col.filter.showAddButton ?? true"
  [showClearButton]="col.filter.showClearButton ?? true"
  [showApplyButton]="col.filter.showApplyButton ?? true"
  [maxConstraints]="10"
>
  <ng-template pTemplate="header">
    <div class="px-3 pt-3 pb-0">
      <span class="font-bold">{{ col?.filter?.placeholder || 'Search By ' + col?.name }}</span>
    </div>
  </ng-template>
</p-columnFilter>
