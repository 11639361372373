<!-- <div *ngIf="documentUrl" class="w-full flex gap-2 mb-3">
<a [href]="documentUrl" [download]="documentName">Download {{documentName}}</a>
</div> -->
<!-- <button pButton [label]="'Download ' + documentName" (click)="downloadAttachment(documentUrl)"></button> -->
@if (preivewMode == 'inline' && fileViewerObject) {
  <div class="felx flex-column">
    <div class="flex flex-row gap-1">
      @if (!isLoading) {
        <i [class]="'h-3rem w-2rem min-w-2rem fiv-viv fiv-icon-' + documentExtension"> </i>
      } @else {
        <p-skeleton width="6rem" height="5rem" styleClass=""></p-skeleton>
      }
      <div class="flex flex-row justify-content-between relative min-w-0 align-items-center w-full">
        <div class="w-full flex flex-row text-700 gap-2 align-items-center min-w-0">
          @if (isLoading) {
            <p-skeleton width="100%" height="1rem" styleClass="mb-2"></p-skeleton>
          }
          @if (!isLoading) {
            <app-text-view [data]="documentName" [useStaticMaxWidth]="false" class="text-2xl"></app-text-view>
            <app-date-view [useTimeAgo]="true" [data]="data?.creationDate" style="text-wrap: nowrap"></app-date-view>
          }
        </div>
        <div class="flex flex-row gap-1">
          @if (!inProgress && documentUrl) {
            <app-button
              [action]="downloadAttachmentAction"
              [pTooltip]="'Download ' + (data?.size | humanizeFileSize)"
              [tooltipPosition]="'top'"
            ></app-button>
          }
          @if (!inProgress && documentUrl && showDeleteButton) {
            <app-button [action]="deleteAttachmentAction"></app-button>
          }
          @if (inProgress) {
            <app-button [action]="cancelRequestAction"></app-button>
          }
        </div>
      </div>
    </div>
    @if (inProgress) {
      <div class="w-12 flex flex-column gap-2 mt-1">
        <!-- <p>Your File is Downloading Please Wait...</p> -->
        <app-progressbar-view [bgColor]="progressBg" [data]="progress"></app-progressbar-view>
        <!-- <p>{{progress | parseToFixedNumber : 'int'}}%</p> -->
      </div>
    }
  </div>
  <app-file-viewer-switch [data]="fileViewerObject"></app-file-viewer-switch>
} @else {
  @if (data) {
    <div class="h-12rem w-9rem flex flex-column">
      <div
        class="preview-parent h-8rem w-9rem bg-black-alpha-10 hover:bg-black-alpha-50 relative flex justify-content-center align-items-center cursor-pointer"
        (click)="openPreviewDialog()"
      >
        <i class="far fa-eye text-gray-200 preview-icon" [pTooltip]="'Preview'" tooltipPosition="top"></i>
        @if (!isLoading) {
          <i [class]="'h-5rem w-6rem fiv-viv fiv-icon-' + documentExtension"> </i>
        } @else {
          <p-skeleton width="6rem" height="5rem" styleClass=""></p-skeleton>
        }
        <div class="flex flex-column absolute top-0 right-0 z-10">
          @if (!inProgress && documentUrl) {
            <i
              (click)="$event?.stopPropagation(); $event?.preventDefault(); downloadAttachment(documentUrl)"
              [pTooltip]="'Download ' + (data?.size | humanizeFileSize)"
              [tooltipPosition]="'top'"
              class="hover:bg-white-alpha-10 p-1 pi pi-cloud-download text-400 cursor-pointer"
            ></i>
          }
          @if (!inProgress && documentUrl && showDeleteButton) {
            <i
              (click)="$event?.stopPropagation(); $event?.preventDefault(); deleteAttachment($event)"
              [pTooltip]="'Delete'"
              [tooltipPosition]="'top'"
              class="hover:bg-white-alpha-10 p-1 pi pi-trash text-red-600 cursor-pointer"
            ></i>
          }
          @if (inProgress) {
            <i
              (click)="cancelRequestFunction()"
              [pTooltip]="'Cancel'"
              [tooltipPosition]="'top'"
              class="hover:bg-white-alpha-10 p-1 pi pi-times text-400 cursor-pointer"
            ></i>
          }
        </div>
      </div>
      <div class="w-full bottom-0 left-0 bg-black-alpha-30 flex flex-column p-2 text-700" style="min-height: 4rem">
        @if (isLoading) {
          <p-skeleton width="100%" height="1rem" styleClass="mb-2"></p-skeleton>
        }
        @if (isLoading) {
          <p-skeleton width="100%" height="1rem" styleClass=""></p-skeleton>
        }
        @if (!isLoading) {
          <app-text-view [data]="documentName" [useStaticMaxWidth]="false"></app-text-view>
        }
        @if (extraText) {
          <app-text-view [data]="extraText" [useStaticMaxWidth]="false"></app-text-view>
        }
        <app-date-view [data]="data?.creationDate"></app-date-view>
        @if (inProgress) {
          <div class="w-8rem flex flex-column gap-2 mt-1">
            <!-- <p>Your File is Downloading Please Wait...</p> -->
            <app-progressbar-view [bgColor]="progressBg" [data]="progress"></app-progressbar-view>
            <!-- <p>{{progress | parseToFixedNumber : 'int'}}%</p> -->
          </div>
        }
      </div>
    </div>
  }
}
