import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BaseFilter, IDynamicComponent, getEnumOptions } from '@shared/classes';
import { DropDownInputComponent } from '@shared/components/ui/input-fields/drop-down-input/drop-down-input.component';

@Component({
  selector: 'app-enum-filter',
  templateUrl: './enum-filter.component.html',
  styleUrls: ['./enum-filter.component.scss'],
})
export class EnumFilterComponent extends BaseFilter implements OnInit {
  dropDownInput?: IDynamicComponent;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.initInput();
  }

  initInput() {
    this.dropDownInput = {
      componentType: DropDownInputComponent,
      options: {
        label: '',
        placeholder: 'Select ' + (this?.col?.name || 'Value'),
        floatLabel: false,
        name: 'enum-selector',
        control: new FormControl(null),
        inputOptions: {
          dropDownInput: {
            multi: this?.col?.filter?.options?.multi ?? true,
            showClear: true,
            optionLabel: 'label',
            optionValue: 'value',
            items: getEnumOptions(this.col.filter.enumClass),
            badgeView: true,
            appendTo: 'body',
          },
        },
      },
    };
  }
}
