import { Pipe, PipeTransform } from '@angular/core';
import { AppEnumIcons } from '@shared/classes';

@Pipe({
  name: 'getEnumIcon',
})
export class GetEnumIconPipe implements PipeTransform {
  appEnumIcons = AppEnumIcons;
  transform(status: string, ...args: any[]): string {
    return this.appEnumIcons[status] || '';
  }
}
