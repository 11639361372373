import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BaseFilter, IDynamicComponent } from '@shared/classes';
import { TagSearchInputComponent } from '@shared/components/ui/input-fields/tag-search-input/tag-search-input.component';
import { ColumnFilter } from 'primeng/table';

@Component({
  selector: 'app-tags-selector-filter',
  templateUrl: './tags-selector-filter.component.html',
  styleUrl: './tags-selector-filter.component.scss'
})
export class TagsSelectorFilterComponent extends BaseFilter implements OnInit {
  dropDownInput?: IDynamicComponent;
  @ViewChild('columnFilter') columnFilter: ColumnFilter;
  @Input() control;
  constructor() {
    super();
  }

  ngOnInit(): void {
    this.initInput();
  }
  closeFilterPopup() {
    if (this.columnFilter) {
      this.columnFilter.overlayVisible = false;
    }
  }
  initInput() {
    // this.control = new FormControl(null);
    this.dropDownInput = {
      componentType: TagSearchInputComponent,
      options: {
        label: '',
        name: '',
        control: this.control,
        inputOptions: {},
      },
    };
    this.control.valueChanges.subscribe(x => {
      x;
      if (x?.length == 0) {
        this.columnFilter.clearFilter();
      }
    });
  }

  onFilterPopupOpen(filterKey: string) {
    // if (this.control && !this.control?.value?.length) {
    //   this.columnFilter.clearFilter();
    // }
  }
}
