@if (useTimeAgo) {
  <div
    [pTooltip]="data | date: 'yyyy-MM-dd HH:mm:ss'"
    [tooltipPosition]="'top'"
    class="flex flex-row gap-1 align-items-end justify-content-end"
  >
    <span
      class="text-500"
      timeago
      [date]="data | date: 'yyyy-MM-dd HH:mm:ss'"
      style="font-variant-numeric: lining-nums"
    ></span>
    @if (showTimeAgoIcon) {
      <i class="text-500 text-sm pi pi-clock"></i>
    }
  </div>
} @else {
  <span>
    @switch (dateFormat) {
      @case ('long') {
        <span>{{ data | date: 'yyyy-MM-dd HH:mm' }}</span>
      }
      @case ('short') {
        <span>{{ data | date: 'yyyy-MM-dd' }}</span>
      }
      @case ('time') {
        <span>{{ data | date: 'HH:mm' }}</span>
      }
      @default {
        <span>{{ data | date: 'yyyy-MM-dd HH:mm' }}</span>
      }
    }
  </span>
}
