import { Component, Input, OnInit } from '@angular/core';
import { BaseViewItem } from '@shared/classes/view/BaseViewItem';

@Component({
  selector: 'app-date-view',
  templateUrl: './date-view.component.html',
  styleUrls: ['./date-view.component.scss'],
})
export class DateViewComponent extends BaseViewItem implements OnInit {
  @Input() dateFormat: 'long' | 'short' | 'time' = 'long';
  @Input() useTimeAgo:boolean = false;
  @Input() showTimeAgoIcon:boolean = true;
  constructor() {
    super();
  }

  ngOnInit(): void {}
}
